import React, { useState } from "react";
import { FaLinkedin, FaTwitter, FaChevronDown, FaMapMarkerAlt, FaPhone, FaEnvelope } from "react-icons/fa";
import { motion } from "framer-motion";
import {submitFeedback} from "../../api/apis";
import {useSnackbar} from "../SnackBarContext";
import {COMMON} from "../GlobalConstant";
import ProductTimeline from "./ProductTimeline";

const OUR_TEAM_DESCRIPTION_PARA_1 = "We are two passionate Singaporeans with a shared love for traveling and exploring new experiences. Our journeys have not only fueled our curiosity but also inspired us to create a platform that connects travelers and shoppers in meaningful ways.";
const OUR_TEAM_DESCRIPTION_PARA_2 = "One of us brings years of experience as a software developer, crafting the technical foundation of the platform, while the other is a product manager, focused on creating user-driven experiences and ensuring the platform's growth. Together, we combine our expertise in tech and our passion for travel to build something truly unique.";
const VISION_PARA_1 = "Ever wanted an item that's only available overseas? Or looked for a way to earn a little extra while traveling? That's exactly what Wanderloot is here for.";
const VISION_PARA_2 = "We connect Looties (shoppers) with Wanderers (travelers), making regionally exclusive items more accessible while helping Wanderers offset their travel costs. Looties create Lootie Quests—public requests for specific items—while Wanderers accept them in exchange for a Wanderer's Reward. Looties get their Wanderloot (often at great prices), and Wanderers earn something extra for their trip. It's a win-win for both sides.";
const VISION_PARA_3 = "Right now, our MVP is just the foundation of what we're building—a community where anyone can be both a Lootie and a Wanderer. We're starting with transactions within Singapore, but this is only the beginning. More features, smarter matching, and global expansion are on the horizon.";
const CONTACT_US_BODY = "We're just getting started, and your feedback—big or small—helps us improve! Let us know your thoughts, and we'll keep making Wanderloot better for you.";

const AboutUs = () => {
    const { showSnackbar } = useSnackbar(); // Use snackbar context
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        message: ""
    });

    const timeline = [
        { year: "MVP", title: "Email verification during login", description: "Ensuring a secure and smooth login experience for all users." },
        { year: "2025", title: "First Major Project", description: "Successfully delivered our flagship product" },
        { year: "2026", title: "Global Expansion", description: "Opened offices in multiple countries" },
        { year: "2027", title: "Industry Leader", description: "Recognized as a leader in innovation" }
    ];

    const team = [
        {
            name: "Pink",
            role: "Co-Founder",
            bio: "Visionary leader with 15+ years of industry experience",
            image: "https://images.unsplash.com/photo-1560250097-0b93528c311a",
            linkedin: "#",
            twitter: "#"
        },
        {
            name: "Johnson",
            role: "Co-Founder",
            bio: "Technical expert driving innovation and growth",
            image: "https://images.unsplash.com/photo-1573496359142-b8d87734a5a2",
            linkedin: "#",
            twitter: "#"
        },
        {
            name: "Michael Chen",
            role: "Head of Design",
            bio: "Creative leader passionate about user experience",
            image: "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e",
            linkedin: "#",
            twitter: "#"
        }
    ];

    const handleSubmit = async (e) => {
        e.preventDefault(); // ✅ Prevents page reload

        try {
            await submitFeedback({
                name: formData.name,
                email: formData.email,
                message: formData.message
            }, showSnackbar);

            // Show success notification
            showSnackbar('Feedback sent!', 'success');
        } catch (error) {
            // Show error returned from API
            showSnackbar('' + error, 'error'); // Show error using SnackbarContext
        }
    };

    return (
        <div className="min-h-screen bg-gray-50">
            {/* Hero Section */}
            <section className="relative h-[80vh] flex items-center justify-center bg-indigo-300 text-white">
                <div className="absolute inset-0 overflow-hidden">
                    <div className="absolute inset-0 bg-black opacity-20"></div>
                </div>
                <div className="relative z-10 text-center px-4">
                    <motion.h1
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        className="text-5xl md:text-7xl font-bold mb-6"
                    >
                        {COMMON.INTRO.TITLE}
                    </motion.h1>
                    <motion.p
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{delay: 0.2}}
                        className="text-xl md:text-2xl max-w-3xl mx-auto"
                    >
                        {COMMON.INTRO.BODY}
                    </motion.p>
                </div>
            </section>

            {/* Mission Statement
            <section className="py-20 px-4">
                <div className="max-w-4xl mx-auto bg-white rounded-lg shadow-xl p-8">
                    <h2 className="text-3xl font-bold mb-6 text-center">Our Mission</h2>
                    <p className="text-lg text-gray-700 leading-relaxed">
                        "To revolutionize cross-border shopping by creating a trusted marketplace where buyers find affordable international products and sellers expand their reach effortlessly."                    </p>
                </div>
            </section>
            */}

            {/* Vision Section */}
            <section className="py-10 px-4">
                <div className="max-w-5xl mx-auto bg-white rounded-2xl shadow-xl p-10 space-y-8">
                    <h2 className="text-4xl font-bold text-center text--600 mb-8">Our Vision</h2>
                    <div className="space-y-6 text-lg leading-relaxed text-gray-700">
                        <p className="font-medium text-xl text--800"> {VISION_PARA_1}</p>
                        <p>{VISION_PARA_2}</p>
                        <p>{VISION_PARA_3}</p>
                    </div>
                </div>
            </section>

            {/* Timeline */}
            <section className="py-10 bg-gray-100 px-4">
                <ProductTimeline/>
            </section>

            {/* Team Section */}
            <section className="py-10 px-4">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl font-bold mb-12 text-center">Our Team</h2>
                    <p className="text-lg text-gray-600 leading-relaxed mb-8">
                        {OUR_TEAM_DESCRIPTION_PARA_1}
                    </p>
                    <p className="text-lg text-gray-600 leading-relaxed mb-12">
                        {OUR_TEAM_DESCRIPTION_PARA_2}
                    </p>
                    {/* key members profile
                    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {team.map((member, index) => (
                            <motion.div
                                key={index}
                                whileHover={{y: -10}}
                                className="bg-white rounded-lg shadow-lg overflow-hidden"
                            >
                                <img
                                    src={member.image}
                                    alt={member.name}
                                    className="w-full h-64 object-cover"
                                />
                                <div className="p-6">
                                    <h3 className="text-xl font-bold mb-2">{member.name}</h3>
                                    <p className="text-indigo-600 mb-2">{member.role}</p>
                                    <p className="text-gray-600 mb-4">{member.bio}</p>
                                    <div className="flex space-x-4">
                                        <a href={member.linkedin} className="text-gray-600 hover:text-indigo-600">
                                            <FaLinkedin size={24}/>
                                        </a>
                                        <a href={member.twitter} className="text-gray-600 hover:text-indigo-400">
                                            <FaTwitter size={24}/>
                                        </a>
                                    </div>
                                </div>
                            </motion.div>
                        ))}
                    </div>
                   */}
                </div>
            </section>

            {/* Contact Section */}
            <section className="py-10 bg-gray-100 px-2">
                <div className="max-w-6xl mx-auto">
                    <h2 className="text-3xl font-bold mb-8 text-center">Contact Us</h2>
                    <p className="text-lg text-gray-600 leading-relaxed mb-12">
                        {CONTACT_US_BODY}
                    </p>
                    <div className="grid md:grid-cols-2 gap-12">
                        <div>
                            <form onSubmit={handleSubmit} className="space-y-6">
                                <div>
                                    <label className="block text-gray-700 mb-2" htmlFor="name">
                                        Name
                                    </label>
                                    <input
                                        type="text"
                                        id="name"
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        required
                                        onChange={(e) =>
                                            setFormData({...formData, name: e.target.value})
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 mb-2" htmlFor="email">
                                        Email
                                    </label>
                                    <input
                                        type="email"
                                        id="email"
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        required
                                        onChange={(e) =>
                                            setFormData({...formData, email: e.target.value})
                                        }
                                    />
                                </div>
                                <div>
                                    <label className="block text-gray-700 mb-2" htmlFor="message">
                                        Message
                                    </label>
                                    <textarea
                                        id="message"
                                        rows="4"
                                        className="w-full p-3 border border-gray-300 rounded-lg"
                                        required
                                        onChange={(e) =>
                                            setFormData({...formData, message: e.target.value})
                                        }
                                    ></textarea>
                                </div>
                                <button
                                    type="submit"
                                    className="w-full bg-indigo-600 text-white py-3 px-6 rounded-lg hover:bg-indigo-700 transition-colors"
                                >
                                    Send Message
                                </button>
                            </form>
                        </div>
                        <div className="space-y-6">
                            {/*
                            <div className="flex items-start space-x-4">
                                <FaMapMarkerAlt className="text-indigo-600 text-xl flex-shrink-0 mt-1"/>
                                <div>
                                    <h3 className="font-semibold mb-1">Address</h3>
                                    <p className="text-gray-600">123 Nassim Road, Singapore 12345</p>
                                </div>

                            </div>
                            <div className="flex items-start space-x-4">
                                <FaPhone className="text-indigo-600 text-xl flex-shrink-0 mt-1"/>
                                <div>
                                    <h3 className="font-semibold mb-1">Phone</h3>
                                    <p className="text-gray-600">+65 6333 1111</p>
                                </div>
                            </div>
                            */}
                            <div className="flex items-start space-x-4">
                                <FaEnvelope className="text-indigo-600 text-xl flex-shrink-0 mt-1"/>
                                <div>
                                    <h3 className="font-semibold mb-1">Email</h3>
                                    <p className="text-gray-600">hellowanderloot@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
};

export default AboutUs;