import GuideBannerV2 from "../marketplace/msc/GuideBannerV2";
import {COMMON} from "../GlobalConstant"
import {FiArrowRight} from "react-icons/fi";
import {useNavigate} from "react-router-dom";

const INTRO_BANNER_CARD_TITLE_1 = "Get Exclusive Items, Fast"
const INTRO_BANNER_CARD_DESCRIPTION_1 = "Skip long shipping times—have a Wanderer bring back what you need."
const INTRO_BANNER_CARD_ICON_1 = "🚀";
const INTRO_BANNER_CARD_TITLE_2 = "Transparent Pricing"
const INTRO_BANNER_CARD_DESCRIPTION_2 = "Know the total cost upfront—no hidden fees, no surprises."
const INTRO_BANNER_CARD_ICON_2 = "🔍";
const INTRO_BANNER_CARD_TITLE_3 = "Earn While You Travel"
const INTRO_BANNER_CARD_DESCRIPTION_3 = "Looties save on rare finds, Wanderers make extra cash.";
const INTRO_BANNER_CARD_ICON_3 = "🤝";

const HomePage = () => {
    const navigate = useNavigate(); // Initialize useNavigate

    const IntroBannerCards = () => (
        <div className="grid md:grid-cols-3 gap-6 mb-12 mt-12">
            {[
                {
                    title: INTRO_BANNER_CARD_TITLE_1,
                    description: INTRO_BANNER_CARD_DESCRIPTION_1,
                    icon: INTRO_BANNER_CARD_ICON_1
                },
                {
                    title: INTRO_BANNER_CARD_TITLE_2,
                    description: INTRO_BANNER_CARD_DESCRIPTION_2,
                    icon: INTRO_BANNER_CARD_ICON_2
                },
                {
                    title: INTRO_BANNER_CARD_TITLE_3,
                    description: INTRO_BANNER_CARD_DESCRIPTION_3,
                    icon: INTRO_BANNER_CARD_ICON_3
                }
            ].map((card, index) => (
                <div key={index} className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow">
                    <div className="text-3xl mb-4">{card.icon}</div>
                    <h3 className="text-xl font-semibold mb-2">{card.title}</h3>
                    <p className="text-gray-600">{card.description}</p>
                </div>
            ))}
        </div>
    );

    const handleViewAllProductListing = () => {
        navigate('/marketplace');
    }

    return (
        <div className="min-h-screen bg-gray-50 pt-16">
            <div className="max-w-7xl mx-auto px-4 py-8">
                <div className="bg-indigo-300 text-white rounded-xl overflow-hidden shadow-2xl mb-4 relative">
                    <div className="absolute inset-0 bg-black opacity-20"></div>
                    <div className="max-w-4xl mx-auto mt-10 mb-5 px-6 text-center relative z-10">
                        <h1 className="text-5xl md:text-7xl font-bold mb-6 animate-fade-in">{COMMON.INTRO.TITLE}</h1>
                        <p className="text-xl md:text-2xl max-w-3xl leading-relaxed mx-auto text-gray-100">{COMMON.INTRO.BODY}</p>
                    </div>

                    <div className="flex justify-center mb-5 relative z-10">
                        <button
                            onClick={() => handleViewAllProductListing()}
                            className="flex items-center justify-center w-80 py-3 rounded-full bg-indigo-600 text-white hover:bg-indigo-800 transition-colors duration-200"
                        >
                            Click to explore all product listing
                            <FiArrowRight className="ml-2 w-5 h-5"/>
                        </button>
                    </div>
                </div>
                <IntroBannerCards/>
                <GuideBannerV2/>
            </div>
        </div>
    );
};

export default HomePage;