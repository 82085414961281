import {
    AdComment, AdCommentRes,
    Advertisement, AuthResponse,
    CreateAdRequest, CreateOrderRequest,
    LoginRequest, Orders,
    PageResult,
    RegisterNewAccRequest, SendPinRequest, SubmitAdCommentRequest, SubmitFeedbackRequest, UserOrder,
} from "../type/types";
import API_PATHS from './apiPaths';
import {apiRequest} from "./utils";
const DEFAULT_PROFILE_PHOTO = "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e";

export const fetchAdvertisements = async (
    page: number,
    pageSize: number,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<PageResult<Advertisement> | null> => {
    return apiRequest<PageResult<Advertisement>>(
        'get',
        `${API_PATHS.GET_ADVERTISEMENTS}?page=${page}&pageSize=${pageSize}`,
        undefined,
        showSnackbar,
        false // Does not require authentication
    );
};

export const getUserAds = async (
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<PageResult<Advertisement> | null> => {
    return apiRequest<PageResult<Advertisement>>(
        'get',
        API_PATHS.GET_USER_ADVERTISEMENTS,
        undefined,
        showSnackbar
    ) || [];
};

export const signupNewAccount = async (
    registerNewAccRequest: RegisterNewAccRequest,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<void> => {
    await apiRequest<void>(
        'post',
        API_PATHS.SIGNUP_NEW_ACCOUNT,
        registerNewAccRequest,
        showSnackbar,
        false // Signup does not require authentication
    );
};

export const sendPinToEmail = async (
    sendPinRequest: SendPinRequest,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<void> => {
    await apiRequest<void>(
        'post',
        API_PATHS.SEND_PIN_TO_EMAIL,
        sendPinRequest,
        showSnackbar,
        false, // Requires authentication
    );
};

export const login = async (
    loginRequest: LoginRequest,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<AuthResponse | null> => {
    const response = await apiRequest<AuthResponse>(
        'post',
        API_PATHS.LOGIN,
        loginRequest,
        showSnackbar,
        false // Login does not require authentication
    );

    if (response?.jwt) {
        localStorage.setItem('jwtToken', response.jwt);
    }

    if (response?.avatar) {
        localStorage.setItem('avatar', response.avatar);
    } else {
        localStorage.setItem('avatar', DEFAULT_PROFILE_PHOTO);
    }

    return response;
};

export const createAd = async (
    createAdRequest: CreateAdRequest,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<void> => {
    await apiRequest<void>(
        'post',
        API_PATHS.CREATE_ADVERTISEMENT,
        createAdRequest,
        showSnackbar
    );
};

export const uploadImage = async (
    file: File,  // Ensure this is a real `File` object
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<string | null> => {
    const formData = new FormData();
    formData.append('file', file);

    return await apiRequest<string>(
        'post',
        API_PATHS.UPLOAD_IMAGE,
        formData,
        showSnackbar
    );
};

export const getAdByPublicAdId = async (
    publicAdId: string,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<Advertisement | null> => {
    return apiRequest<Advertisement>(
        'get',
        API_PATHS.GET_AD_BY_PUBLIC_AD_ID,
        undefined, // No body for GET requests
        showSnackbar,
        true, // Requires authentication
        { publicAdId } // Pass query parameters correctly
    );
};

export const getTermsOfService = async (
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<string | null> => {
    return await apiRequest<string>(
        'get',
        API_PATHS.GET_TERMS_OF_SERVICE,
        undefined,
        showSnackbar,
        false,
    );
};

export const getPrivacyPolicy = async (
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<string | null> => {
    return await apiRequest<string>(
        'get',
        API_PATHS.GET_PRIVACY_POLICY,
        undefined,
        showSnackbar,
        false,
    );
};

export const getUserOrder = async (
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<PageResult<UserOrder>> => {
    return apiRequest<PageResult<UserOrder>>(
        'get',
        API_PATHS.GET_USER_ORDER,
        undefined,
        showSnackbar,
        true,
    ) || [];
};

export const submitAdComment = async (
    submitAdCommentRequest: SubmitAdCommentRequest,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<AdComment | null> => {
    return apiRequest<AdComment>(
        'post',
        API_PATHS.SUBMIT_AD_COMMENT,
        submitAdCommentRequest,
        showSnackbar
    );
};

export const getAdComments = async (
    publicAdId: string,
    page: number,
    pageSize: number,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<PageResult<AdCommentRes> | null> => {
    return apiRequest<PageResult<AdCommentRes>>(
        'get',
        API_PATHS.GET_AD_COMMENTS,
        undefined, // No body for GET requests
        showSnackbar,
        true, // Requires authentication
        { publicAdId, page, pageSize } // Pass query parameters correctly
    );
};

export const createOrder = async (
    createOrderRequest: CreateOrderRequest,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<number | null> => {
    return apiRequest<number>(
        'post',
        API_PATHS.CREATE_ORDER,
        createOrderRequest,
        showSnackbar
    );
};

export const submitFeedback = async (
    submitFeedbackRequest: SubmitFeedbackRequest,
    showSnackbar: (message: string, severity?: 'error' | 'success') => void
): Promise<void> => {
    await apiRequest<void>(
        'post',
        API_PATHS.SUBMIT_FEEDBACK,
        submitFeedbackRequest,
        showSnackbar,
        false, // Requires authentication
    );
};



