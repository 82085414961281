import React, {useState, useMemo, useEffect} from "react";
import { FiEye, FiChevronLeft, FiChevronRight, FiSearch } from "react-icons/fi";
import {getUserAds, getUserOrder} from "../../api/apis";
import {useSnackbar} from "../SnackBarContext";
import {Advertisement, Orders} from "../../type/types";
import dayjs from "dayjs";
import {useNavigate} from "react-router-dom";

const OrdersAndAdvertisements = () => {
    const [activeTab, setActiveTab] = useState("orders");
    const [currentPage, setCurrentPage] = useState(1);
    const [searchTerm, setSearchTerm] = useState("");
    const [sortConfig, setSortConfig] = useState({ key: null, direction: "asc" });
    const { showSnackbar } = useSnackbar(); // Use snackbar context
    const [ads, setAds] = useState<Advertisement[]>([]);
    const [orders, setOrders] = useState<Orders[]>([]);
    const navigate = useNavigate();

    const itemsPerPage = 5;

    // Fetch user orders
    const getUserOrders = async () => {
        try {
            const response = await getUserOrder(showSnackbar);
            setOrders(response.content);
        } catch (error) {
            console.error('Error fetching orders:', error);
        }
    }

    useEffect(() => {
        getUserOrders();
    }, []);

    // Fetch user advertisements from the backend
    const getAdList = async () => {
        try {
            const response = await getUserAds(showSnackbar);
            setAds(response.content);
        } catch (error) {
            console.error('Error fetching advertisements:', error);
        }
    };

    useEffect(() => {
        // Fetch advertisements
        getAdList();
    }, []);

    const handleSort = (key) => {
        let direction = "asc";
        if (sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc";
        }
        setSortConfig({ key, direction });
    };

    const sortedData = useMemo(() => {
        const data = activeTab === "orders" ? orders : ads;
        if (!sortConfig.key) return data;

        return [...data].sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
                return sortConfig.direction === "asc" ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
                return sortConfig.direction === "asc" ? 1 : -1;
            }
            return 0;
        });
    }, [activeTab, sortConfig, ads, orders]);

    const filteredData = useMemo(() => {
        return sortedData.filter((item) =>
            Object.values(item).some((value) =>
                value !== null && value !== undefined && value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [sortedData, searchTerm, ads, orders]);

    const paginatedData = useMemo(() => {
        const startIndex = (currentPage - 1) * itemsPerPage;
        return filteredData.slice(startIndex, startIndex + itemsPerPage);
    }, [filteredData, currentPage, ads, orders]);

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);

    const TableHeader = ({ columns }) => (
        <thead className="bg-gray-50">
        <tr>
            {columns.map((column) => (
                <th
                    key={column.key}
                    onClick={() => handleSort(column.key)}
                    className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer hover:bg-gray-100"
                >
                    {column.label}
                    {sortConfig.key === column.key && (
                        <span className="ml-2">
                {sortConfig.direction === "asc" ? "↑" : "↓"}
              </span>
                    )}
                </th>
            ))}
            <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                Actions
            </th>
        </tr>
        </thead>
    );

    const orderColumns = [
        { key: "publicOrderId", label: "Order ID" },
        { key: "startTime", label: "Start Date" },
        { key: "startTime", label: "End Date" },
        { key: "productName", label: "Product Name" },
        { key: "status", label: "Status" },
        { key: "price", label: "Price" },
    ];

    const adColumns = [
        { key: "publicAdId", label: "Advertisement ID" },
        { key: "startTime", label: "Start Date" },
        { key: "endTime", label: "End Date" },
        { key: "name", label: "Product Name" },
        { key: "status", label: "Status" },
        { key: "price", label: "Price" }
    ];

    return (
        <div className="container mx-auto px-4 py-8 mt-16">
            <div className="mb-8">
                <div className="sm:hidden">
                    <select
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500"
                        value={activeTab}
                        onChange={(e) => setActiveTab(e.target.value)}
                    >
                        <option value="orders">Orders</option>
                        <option value="advertisements">Advertisements</option>
                    </select>
                </div>

                <div className="hidden sm:block">
                    <nav className="flex space-x-4" aria-label="Tabs">
                        <button
                            onClick={() => setActiveTab("orders")}
                            className={`${
                                activeTab === "orders"
                                    ? "bg-indigo-100 text-indigo-700"
                                    : "text-gray-500 hover:text-gray-700"
                            } px-3 py-2 font-medium text-sm rounded-md`}
                        >
                            Orders
                        </button>
                        <button
                            onClick={() => setActiveTab("advertisements")}
                            className={`${
                                activeTab === "advertisements"
                                    ? "bg-indigo-100 text-indigo-700"
                                    : "text-gray-500 hover:text-gray-700"
                            } px-3 py-2 font-medium text-sm rounded-md`}
                        >
                            Advertisements
                        </button>
                    </nav>
                </div>
            </div>

            <div className="mb-4">
                <div className="relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                        <FiSearch className="h-5 w-5 text-gray-400" />
                    </div>
                    <input
                        type="text"
                        className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-10 sm:text-sm border-gray-300 rounded-md"
                        placeholder="Search..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                </div>
            </div>

            <div className="bg-white shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <div className="overflow-x-auto">
                    <table className="min-w-full divide-y divide-gray-200">
                        <TableHeader
                            columns={activeTab === "orders" ? orderColumns : adColumns}
                        />
                        <tbody className="bg-white divide-y divide-gray-200">
                        {paginatedData.map((item) => {
                            // Determine field mappings based on activeTab
                            const id = activeTab === "orders" ? item.publicOrderId : item.publicAdId;
                            const startDate = dayjs(item.startTime).format("DD-MMM-YYYY");
                            const endDate = dayjs(item.endTime).format("DD-MMM-YYYY");
                            const productName = activeTab === "orders" ? item.productName : item.name;
                            const price = item.price;

                            const handleViewDetails = (ad) => {
                                if (activeTab === "orders") {
                                    navigate(`/orderDetails/${ad}`);
                                }
                            };

                            // Determine status label & color
                            const statusMapping = activeTab === "orders"
                                ? {
                                    1: { label: "New", color: "bg-yellow-100 text-yellow-800" },
                                    2: { label: "Completed", color: "bg-green-100 text-green-800" },
                                    3: { label: "Cancelled", color: "bg-red-100 text-red-800" },
                                }
                                : {
                                    1: { label: "New", color: "bg-yellow-100 text-yellow-800" },
                                    2: { label: "Completed", color: "bg-green-100 text-green-800" },
                                    3: { label: "Cancelled", color: "bg-red-100 text-red-800" },
                                };

                            const { label, color } = statusMapping[item.status] || { label: "Unknown", color: "bg-gray-100 text-gray-800" };

                            return (
                                <tr key={id} className="hover:bg-gray-50 transition-colors duration-200">
                                    <td className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900">{id}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{startDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{endDate}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">{productName}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full ${color}`}>
                    {label}
                </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">${price}</td>
                                    <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                                        <button
                                            onClick={() => handleViewDetails(item.publicAdId)}
                                            className="text-indigo-600 hover:text-indigo-900 flex items-center justify-end"
                                        >
                                            <FiEye className="h-4 w-4 mr-1"/>
                                            View Details
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}

                        </tbody>
                    </table>
                </div>
            </div>

            <div className="flex items-center justify-between px-4 py-3 bg-white border-t border-gray-200 sm:px-6">
                <div className="flex-1 flex justify-between sm:hidden">
                    <button
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                        className="relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                    >
                        Previous
                    </button>
                    <button
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                        className="ml-3 relative inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 disabled:opacity-50"
                    >
                        Next
                    </button>
                </div>
                <div className="hidden sm:flex-1 sm:flex sm:items-center sm:justify-between">
                    <div>
                        <p className="text-sm text-gray-700">
                            Showing{" "}
                            <span className="font-medium">
                {(currentPage - 1) * itemsPerPage + 1}
              </span>{" "}
                            to{" "}
                            <span className="font-medium">
                {Math.min(currentPage * itemsPerPage, filteredData.length)}
              </span>{" "}
                            of <span className="font-medium">{filteredData.length}</span> results
                        </p>
                    </div>
                    <div>
                        <nav
                            className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px"
                            aria-label="Pagination"
                        >
                            <button
                                onClick={() => setCurrentPage(currentPage - 1)}
                                disabled={currentPage === 1}
                                className="relative inline-flex items-center px-2 py-2 rounded-l-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                            >
                                <span className="sr-only">Previous</span>
                                <FiChevronLeft className="h-5 w-5" />
                            </button>
                            {[...Array(totalPages)].map((_, index) => (
                                <button
                                    key={index + 1}
                                    onClick={() => setCurrentPage(index + 1)}
                                    className={`relative inline-flex items-center px-4 py-2 border text-sm font-medium ${
                                        currentPage === index + 1
                                            ? "z-10 bg-indigo-50 border-indigo-500 text-indigo-600"
                                            : "bg-white border-gray-300 text-gray-500 hover:bg-gray-50"
                                    }`}
                                >
                                    {index + 1}
                                </button>
                            ))}
                            <button
                                onClick={() => setCurrentPage(currentPage + 1)}
                                disabled={currentPage === totalPages}
                                className="relative inline-flex items-center px-2 py-2 rounded-r-md border border-gray-300 bg-white text-sm font-medium text-gray-500 hover:bg-gray-50 disabled:opacity-50"
                            >
                                <span className="sr-only">Next</span>
                                <FiChevronRight className="h-5 w-5" />
                            </button>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OrdersAndAdvertisements;
