import React, { useEffect } from "react";
import { FaRocket, FaCompass, FaGlobeAmericas } from "react-icons/fa";
import "aos/dist/aos.css";
import AOS from 'aos';

const ProductTimeline = () => {
    useEffect(() => {
        AOS.init({
            duration: 1000,
            once: true
        });
    }, []);

    const timelineData = [
        {
            title: "Minimum Viable Product",
            icon: <FaRocket className="text-indigo-600 w-8 h-8" />,
            items: [
                "Email verification during login: Ensuring a secure and smooth login experience for all users.",
                "Nickname and Avatar customization: Users will have the ability to choose a nickname and upload an avatar, adding a personal touch and making interactions more engaging.",
                "Lootie Quest creation: Shoppers (Looties) can create offers for Wanderers to accept, kickstarting the marketplace interaction.",
                "Wanderer’s Offer view: Wanderers can view and accept active Lootie Quests, making it easier to connect and fulfill requests."
            ],
            gradient: "from-indigo-500 to-purple-500"
        },
        {
            title: "Upcoming Features",
            icon: <FaCompass className="text-blue-600 w-8 h-8" />,
            items: [
                "Smarter Matching and Boosting Supply: Wanderers will soon be able to input their travel plans and receive notifications for relevant Lootie Quests—connecting buyers and travelers more efficiently.",
                "Feature Poll: We’ll let our loyal users vote on which features they’d like to see next, bringing the most requested changes to life."
            ],
            gradient: "from-blue-500 to-teal-500"
        },
        {
            title: "Future Vision",
            icon: <FaGlobeAmericas className="text-green-600 w-8 h-8" />,
            items: [
                "Secure Transactions: Future updates will introduce secure escrow payments, providing an extra layer of safety and confidence during transactions",
                "Global Expansion: Once we’ve optimized the platform for local demand and users, we’ll expand beyond Singapore to bring Looties and Wanderers together across borders."
            ],
            gradient: "from-green-500 to-emerald-500"
        }
    ];
    return (
        <div className="w-full max-w-none min-h-screen">
            <div className="max-w-3xl mx-auto">
            <h2 className="text-3xl font-bold text-center text-gray-900 mb-12">Product Updates Timeline</h2>

                <div className="relative">
                    {/* Timeline line */}
                    <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-0.5 bg-gray-200" />

                    {timelineData.map((section, index) => (
                        <div
                            key={index}
                            className="mb-16 relative"
                            data-aos="fade-up"
                            data-aos-delay={index * 100}
                        >
                            <div className="flex items-center justify-center mb-4">
                                <div className="bg-white p-2 rounded-full shadow-lg z-10 transform transition-transform hover:scale-110">
                                    {section.icon}
                                </div>
                            </div>

                            <div className="bg-white rounded-lg shadow-lg p-6 mx-4 transform transition-all hover:scale-102 hover:shadow-xl">
                                <div className={`h-1 w-full bg-gradient-to-r ${section.gradient} rounded-t-lg -mt-6 mb-4`} />
                                <h3 className="text-xl font-semibold text-gray-900 mb-4">{section.title}</h3>
                                <ul className="space-y-4">
                                    {section.items.map((item, itemIndex) => (
                                        <li
                                            key={itemIndex}
                                            className="flex items-start group hover:bg-gray-50 p-2 rounded-lg transition-colors duration-200"
                                        >
                                            <span className="h-2 w-2 mt-2 mr-3 rounded-full bg-gray-400 group-hover:bg-indigo-500 transition-colors duration-200" />
                                            <span className="text-gray-600 flex-1 leading-relaxed text-sm">{item}</span>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default ProductTimeline;