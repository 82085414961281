export const COMMON = {
    INTRO: {
        TITLE: "Travelers Earn, Shoppers Save — Connecting You to Exclusive Finds",
        BODY: "Shoppers get better deals, travelers earn rewards — everyone wins.",
    },

    AD: {
        DESTINATION_COUNTRY_TIP: "Only Singapore for now!"
    },

};
