import {BrowserRouter as Router, Route, Routes } from "react-router-dom";
import {MainContextProvider} from "./MainContext";
import { SnackbarProvider } from "./SnackBarContext";
import TermsOfServicePage from "./auth/TermsOfServicePage";
import React from "react";
import LoginSignupPage from "./auth/LoginSignUpPage";
import HomePage from "./home/HomePage";
import NavigationBar from "./home/HeaderPageV3";
import OrderDetails from "./order/OrderDetailPage";
import BuyAdvertisementForm from "./ad/CreateBuyAd";
import OrdersAndAdvertisements from "./user/OrderAndAdPage";
import AboutUs from "./contact/AboutUsPage";
import PrivacyPolicyPage from "./auth/PrivacyPolicy";
import ForgetPasswordPage from "./auth/ForgetPasswordPage";
import PasswordResetPage from "./auth/PasswordResetPage";
import ProductMarketplace from "./marketplace/ProductMarketplace";
import ListingCreationPage from "./ad/CreateListingLandingPage";
import SellAdvertisementForm from "./ad/CreateSellAd";

export default function App() {
    return (
            <Router>
                {/* Router must wrap all components using useNavigate */}
                <MainContextProvider>
                    <SnackbarProvider>
                        <NavigationBar />
                        {/* Define your Routes here */}
                        <Routes>
                            <Route path="/" element={
                                <SnackbarProvider>
                                    <HomePage />
                                </SnackbarProvider>
                            } />
                            {/* Create Order*/}
                            <Route path="/createOrder/:publicAdId" element={
                                <SnackbarProvider>
                                    <OrderDetails />
                                </ SnackbarProvider>
                            } />
                            {/* Create Order*/}
                            <Route path="/orderDetails/:publicAdId" element={
                                <SnackbarProvider>
                                    <OrderDetails />
                                </ SnackbarProvider>
                            } />
                            {/* User's page to view orders and ads*/}
                            <Route path="/userPage" element={
                                <SnackbarProvider>
                                    <OrdersAndAdvertisements />
                                </ SnackbarProvider>
                            } />
                            {/* Contact Us page*/}
                            <Route path="/aboutUs" element={
                                <SnackbarProvider>
                                    <AboutUs />
                                </ SnackbarProvider>
                            } />
                            {/* Default route for Home Page */}
                            <Route path="/home" element={<HomePage />} />

                            {/* Terms of Service and Privacy Policy*/}
                            <Route path="/termsOfService" element={<TermsOfServicePage />} />
                            <Route path="/privacyPolicy" element={<PrivacyPolicyPage />} />

                            {/* Login and signup page */}
                            <Route path="/login" element={<LoginSignupPage />} />

                            {/* Forget Password Page */}
                            <Route path="/forgetPassword" element={<ForgetPasswordPage />} />

                            {/* Reset Password Page */}
                            <Route path="/resetPassword" element={<PasswordResetPage/>} />

                            {/* New product marketplace page */}
                            <Route path="/marketplace" element={<ProductMarketplace/>} />

                            {/* Create listing landing page*/}
                            <Route path="/createListingLandingPage" element={<ListingCreationPage/>} />

                            {/* Create Buy Ad*/}
                            <Route path="/createBuyAd" element={<BuyAdvertisementForm/>} />

                            {/* Create Sell Ad*/}
                            <Route path="/createSellAd" element={<SellAdvertisementForm/>} />
                        </Routes>
                    </SnackbarProvider>
                </MainContextProvider>
            </Router>
    );
}