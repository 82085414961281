export const countries = [
    //'China',
    'Thailand',
    'Malaysia',
    'Indonesia',
    'Korea',
    'Japan',
    'Vietnam',
    // 'USA',
    // 'UK',
    // 'Spain',
    // 'Australia',
    // 'Canada',
    // 'Germany',
    // 'Brazil',
    // 'Switzerland'
];
