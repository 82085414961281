import React, {useCallback, useState} from "react";
import { format } from "date-fns";
import { FiUpload, FiTrash2 } from "react-icons/fi";
import { useDropzone } from "react-dropzone";
import {createAd, uploadImage} from "../../api/apis";
import {useSnackbar} from "../SnackBarContext";
import {useNavigate} from "react-router-dom";
import {countries} from "../../Countries";
import {COMMON} from "../GlobalConstant";
import {adTypeMap} from "../../enum/enums";

const DESCRIPTION_PLACEHOLDER = "Looking for 2 Starbucks Tokyo Limited Sakura Tumblers (2024 edition). Must be brand new and sealed. Willing to pay up to ¥4,000 each. Can meet at Orchard MRT on weekday evenings for pickup.";
//const PRODUCT_NAME_PLACEHOLDER = "Starbucks Tokyo Limited Sakura Tumblers (2024 edition)";
const PAGE_HEADER = "Create Lootie Quest (Want to buy)";

const BuyAdvertisementForm = () => {
    const { showSnackbar } = useSnackbar(); // Use snackbar context
    const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
    const navigate = useNavigate(); // Initialize useNavigate

    const [step, setStep] = useState(1);
    // Get today's date in YYYY-MM-DD format
    const today = new Date().toISOString().split("T")[0];

    const [formData, setFormData] = useState({
        productName: "",
        quantity: 1,
        price: "0",
        description: "",
        sourceCountry: "",
        destinationCountry: "Singapore",
        travellerFee: "0",
        startTime: today,
        endTime: "",
        image: null
    });

    const [errors, setErrors] = useState({});
    const [isSubmitting, setIsSubmitting] = useState(false);

    const toLocalDateTime = (epoch) => {
        return new Date(epoch).toISOString().replace("Z", ""); // Converts to `YYYY-MM-DDTHH:mm:ss`
    };

    // 🔹 Drag-and-Drop Handler
    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (file && file.size <= 5 * 1024 * 1024) {
            // Generate a preview using URL.createObjectURL
            setPreviewImageUrl(URL.createObjectURL(file));

            // Upload the image
            const uploadedFilePath = await uploadImage(file, showSnackbar);
            setFormData(prev => ({
                ...prev,
                image: uploadedFilePath // Only update the image, keeping all other fields
            }));

            setErrors({ ...errors, image: "" });
        } else {
            setErrors({ ...errors, image: "File size must be less than 5MB" });
        }
        showSnackbar("File upload success!", "success");
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: {
            "image/*": [".jpeg", ".jpg", ".png", ".webp"]
        },
        maxSize: 5 * 1024 * 1024
    });

    const createAdvertisement = async () => {
        // Implement form submission logic here

        if (validateStep(4)) {
            setIsSubmitting(true);
            try {
                // Simulated API call
                if (!formData) {
                    showSnackbar('Error with creating advertisement', 'error'); // Show error using SnackbarContext
                    return;
                }
                const publicAdId = await createAd({
                        productName: formData.productName,
                        price: formData.price,
                        travellerFee: formData.travellerFee,
                        sourceCountry: formData.sourceCountry,
                        imageUrl: formData.image,
                        quantity: formData.quantity,
                        description: formData.description,
                        startTime: toLocalDateTime(formData.startTime),
                        endTime: toLocalDateTime(formData.endTime),
                        adType: adTypeMap.buy
                    }
                    , showSnackbar);

                showSnackbar('Advertisement created!', 'success'); // Show error using SnackbarContext

                // Redirect to AdList page and pass the publicAdId as a query parameter
                navigate(`/marketplace`);
            } catch (error) {
                showSnackbar('Error with creating advertisement', 'error'); // Show error using SnackbarContext
            } finally {
                setIsSubmitting(false);
            }
        }
    };

    const validateStep = (currentStep) => {
        const newErrors = {};

        switch (currentStep) {
            case 1:
                //if (!formData.productName.trim()) newErrors.productName = "Product name is required";
                if (formData.quantity < 1) newErrors.quantity = "Quantity must be positive";
                if (!formData.price || parseFloat(formData.price) <= 0) newErrors.price = "Price must be greater than zero";
                break;

            case 2:
                if (!formData.sourceCountry) newErrors.sourceCountry = "Source country is required";
                if (!formData.destinationCountry) newErrors.destinationCountry = "Destination country is required";
                if (formData.sourceCountry === formData.destinationCountry) newErrors.destinationCountry = "Source and destination must be different";
                if (!formData.travellerFee || parseFloat(formData.travellerFee) <= 0) newErrors.travellerFee = "Traveller fee must be positive";
                if (!formData.startTime) newErrors.startTime = "Start date is required";
                if (!formData.endTime) newErrors.endTime = "End date is required";
                if (formData.startTime && formData.endTime && new Date(formData.startTime) >= new Date(formData.endTime)) {
                    newErrors.endTime = "End date must be after start date";
                }
                break;

            case 3:
                if (!formData.image) newErrors.image = "Image is required";
                break;

            default:
                break;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const handleNext = () => {
        if (validateStep(step)) {
            setStep(step + 1);
        }
    };

    const handleBack = () => {
        setStep(step - 1);
    };

    const renderStep = () => {
        switch (step) {
            case 1:
                return (
                    <div className="space-y-6">
                        {/*
                        <div>
                            <label className="block text-sm font-medium text-foreground mb-2">Product Name</label>
                            <input
                                type="text"
                                placeholder={PRODUCT_NAME_PLACEHOLDER}
                                value={formData.productName}
                                onChange={(e) => setFormData({...formData, productName: e.target.value})}
                                className={`w-full p-3 border rounded-md border-gray-300 shadow-sm`}
                            />
                            {errors.productName && <p className="text-red-500 text-sm mt-1">{errors.productName}</p>}
                        </div>
                        */}

                        <div>
                            <label className="block text-sm font-medium text-foreground mb-2">
                                Quest Description
                                <span className="text-xs text-gray-500 ml-1">(Include condition, brand, and pickup preferences)</span>
                            </label>
                            <textarea
                                placeholder={DESCRIPTION_PLACEHOLDER}
                                value={formData.description}
                                onChange={(e) => setFormData({...formData, description: e.target.value})}
                                className={`w-full p-3 border rounded-md border-gray-300 shadow-sm h-32`}
                            />
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-foreground mb-2">Quantity</label>
                            <input
                                type="number"
                                min="1"
                                defaultValue={1}
                                value={formData.quantity}
                                onChange={(e) => setFormData({...formData, quantity: parseInt(e.target.value)})}
                                className={`w-full p-3 border rounded-md border-gray-300 shadow-sm`}
                            />
                            {errors.quantity && <p className="text-red-500 text-sm mt-1">{errors.quantity}</p>}
                        </div>

                        <div>
                            <label className="block text-sm font-medium text-foreground mb-2">Product Standard Retail
                                Price</label>
                            <div className="relative">
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2">$</span>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={formData.price}
                                    onChange={(e) => setFormData({...formData, price: e.target.value})}
                                    className={`w-full p-3 pl-6 border rounded-md border-gray-300 shadow-sm`}
                                />
                            </div>
                            {errors.price && <p className="text-red-500 text-sm mt-1">{errors.price}</p>}
                        </div>
                    </div>
                );

            case 2:
                return (
                    <div className="space-y-6">
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-foreground mb-2">Source Country</label>
                                <select
                                    value={formData.sourceCountry}
                                    onChange={(e) => setFormData({...formData, sourceCountry: e.target.value})}
                                    className={`w-full p-3 border rounded-md border-gray-300 shadow-sm`}
                                >
                                    <option value="">Select country</option>
                                    {countries.map((country) => (
                                        <option key={country} value={country}>{country}</option>
                                    ))}
                                </select>
                                {errors.sourceCountry &&
                                    <p className="text-red-500 text-sm mt-1">{errors.sourceCountry}</p>}
                            </div>

                            <div>
                                <label
                                    className="group animate-pulse relative block text-sm font-medium text-foreground mb-2">
                                    Destination Country
                                    <span className="ml-1 cursor-help group inline-flex items-center">
                                        <img
                                            src="/info-icon.png"
                                            alt="Info"
                                            className="w-3 h-3"
                                        />
                                        <div
                                            className="invisible group-hover:visible absolute left-0 -top-12 w-72 p-2 bg-gray-800 text-white text-xs rounded-lg shadow-lg transition-opacity duration-200 z-10">
                                            {COMMON.AD.DESTINATION_COUNTRY_TIP}
                                        </div>
                                    </span>
                                </label>
                                <select
                                    value="Singapore"
                                    className="w-full p-3 border rounded-md border-gray-300 shadow-sm bg-gray-100 cursor-not-allowed"
                                    disabled
                                >
                                    <option value="Singapore">Singapore</option>
                                </select>
                                {errors.destinationCountry &&
                                    <p className="text-red-500 text-sm mt-1">{errors.destinationCountry}</p>}
                            </div>
                        </div>

                        <div>
                            <label
                                className="group animate-pulse relative block text-sm font-medium text-foreground mb-2">
                                Wanderer's Reward
                                <span className="ml-1 cursor-help group inline-flex items-center">
                                        <img
                                            src="/info-icon.png"
                                            alt="Info"
                                            className="w-3 h-3"
                                        />
                                        <div
                                            className="invisible group-hover:visible absolute left-0 -top-12 w-72 p-2 bg-gray-800 text-white text-xs rounded-lg shadow-lg transition-opacity duration-200 z-10">
                                            Tip: The Wanderer's Reward is typically around 10% of the item's retail price, but you can adjust it based on demand and effort required.
                                        </div>
                                    </span>
                            </label>
                            <div className="relative">
                                <span className="absolute left-3 top-1/2 transform -translate-y-1/2">$</span>
                                <input
                                    type="number"
                                    step="0.01"
                                    value={formData.travellerFee}
                                    onChange={(e) => setFormData({...formData, travellerFee: e.target.value})}
                                    className="w-full p-3 pl-6 border rounded-md border-gray-300 shadow-sm"
                                />
                            </div>
                            {errors.travellerFee && <p className="text-red-500 text-sm mt-1">{errors.travellerFee}</p>}
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                            <div>
                                <label className="block text-sm font-medium text-foreground mb-2">Earliest Arrival Date</label>
                                <input
                                    type="date"
                                    value={formData.startTime ? new Date(formData.startTime).toISOString().split("T")[0] : ""}
                                    onChange={(e) => setFormData({...formData, startTime: e.target.value})}
                                    className="w-full p-3 pl-6 border rounded-md border-gray-300 shadow-sm"
                                />
                                {errors.startTime && <p className="text-red-500 text-sm mt-1">{errors.startTime}</p>}
                            </div>

                            <div>
                                <label className="block text-sm font-medium text-foreground mb-2">Latest Arrival Date</label>
                                <input
                                    type="date"
                                    value={formData.endTime ? new Date(formData.endTime).toISOString().split("T")[0] : ""}
                                    onChange={(e) => setFormData(prev => ({...prev, endTime: e.target.value}))}
                                    className="w-full p-3 pl-6 border rounded-md border-gray-300 shadow-sm"
                                />
                                {errors.endTime && <p className="text-red-500 text-sm mt-1">{errors.endTime}</p>}
                            </div>
                        </div>

                        <div className="mt-4 p-4 bg-cyan-50 rounded-lg">
                            <h4 className="text-cyan-800 font-medium mb-2">Set Your Preferred Delivery Window</h4>
                            <p className="text-cyan-600 text-sm">Choose the earliest and latest dates you're willing to
                                receive your item. This helps travelers plan their trips accordingly.</p>
                        </div>
                    </div>
                );

            case 3:
                return (
                    <div className="space-y-6">
                        <div
                            {...getRootProps()}
                            className={`border-2 border-dashed ${errors.image ? "border-destructive" : "border-input"} rounded-lg p-8 text-center cursor-pointer hover:border-primary transition-colors`}
                        >
                            <input {...getInputProps()} />
                            <FiUpload className="mx-auto h-12 w-12 text-accent"/>
                            <p className="mt-2">Drag & drop an image here, or click to select</p>
                            <p className="text-sm text-accent">JPG, PNG, WebP (max 5MB)</p>
                        </div>

                        {formData.image && (
                            <div className="relative">
                                <img
                                    src={previewImageUrl}
                                    alt="Preview"
                                    className="w-full h-64 object-contain rounded-lg"
                                />
                                <button
                                    onClick={() => setFormData({ ...formData, image: null })}
                                    className="absolute top-2 right-2 p-2 bg-destructive text-white rounded-full hover:bg-opacity-90 transition-opacity"
                                >
                                    <FiTrash2 />
                                </button>
                            </div>
                        )}
                        {errors.image && <p className="text-red-500 text-sm mt-1">{errors.image}</p>}
                    </div>
                );

            case 4:
                return (
                    <div className="space-y-8">
                        <div className="bg-card p-6 rounded-lg shadow-sm">
                            <h3 className="text-lg font-semibold mb-4">Product Details</h3>
                            <div className="grid grid-cols-2 gap-4">
                                {/*
                                <div>
                                    <p className="text-sm text-accent underline">Product Name</p>
                                    <p className="font-medium">{formData.productName}</p>
                                </div>
                                */}
                                <div>
                                    <p className="text-sm text-accent underline">Quantity</p>
                                    <p className="font-medium">{formData.quantity}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-accent underline">Price</p>
                                    <p className="font-medium">${formData.price}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-accent underline">Description</p>
                                    <p className="font-medium">{formData.description || "N/A"}</p>
                                </div>
                            </div>
                        </div>

                        <div className="bg-card p-6 rounded-lg shadow-sm">
                            <h3 className="text-lg font-semibold mb-4">Shipping Details</h3>
                            <div className="grid grid-cols-2 gap-4">
                                <div>
                                    <p className="text-sm text-accent underline">Source Country</p>
                                    <p className="font-medium">{formData.sourceCountry}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-accent underline">Destination Country</p>
                                    <p className="font-medium">{formData.destinationCountry}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-accent underline">Wanderer's Reward</p>
                                    <p className="font-medium">${formData.travellerFee}</p>
                                </div>
                                <div>
                                    <p className="text-sm text-accent underline">Date Range</p>
                                    <p className="font-medium">
                                        {formData.startTime ? format(new Date(formData.startTime), "dd MMM yyyy") : "N/A"} - {formData.endTime ? format(new Date(formData.endTime), "dd MMM yyyy") : "N/A"}
                                    </p>
                                </div>
                            </div>
                        </div>

                        {formData.image && (
                            <div className="bg-card p-6 rounded-lg shadow-sm">
                                <h3 className="text-lg font-semibold mb-4">Product Image</h3>
                                <img
                                    src={previewImageUrl}
                                    alt="Product"
                                    className="w-full h-64 object-contain rounded-lg"
                                />
                            </div>
                        )}
                    </div>
                );

            default:
                return null;
        }
    };

    return (
        <div className="min-h-screen mt-16 bg-gray-50 px-8 py-4">
            <div className="max-w-3xl mx-auto">
                <div className="mb-8">
                    <div className="flex items-center justify-between mb-4">
                        <h1 className="text-[24px] font-bold text-black">
                           {PAGE_HEADER}
                        </h1>

                        <div className="flex items-center space-x-2">
                            {Array.from({length: 4}, (_, i) => (
                                <div
                                    key={i}
                                    className={`w-3 h-3 rounded-full ${
                                        i + 1 === step ? "bg-sky-500" : "bg-gray-300"
                                    }`}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="h-1 bg-gray-200 rounded-full">
                        <div
                            className={`h-full bg-sky-500 rounded-full transition-all duration-300`}
                            style={{width: `${(step / 4) * 100}%`}}
                        />
                    </div>

                </div>

                <div className="bg-white p-6 rounded-lg shadow-sm">
                    {renderStep()}

                    <div className="mt-8 flex justify-between">
                        {step > 1 && (
                            <button
                                onClick={handleBack}
                                className="px-6 py-2 bg-gray-200 text-black rounded-lg transition-colors font-inter"
                            >
                                Back
                            </button>
                        )}

                        {step < 4 ? (
                            <button
                                onClick={handleNext}
                                className="ml-auto px-6 py-2 bg-sky-500 text-white rounded-lg transition-colors font-inter"
                            >
                                Next
                            </button>
                        ) : (
                            <button
                                onClick={createAdvertisement}
                                disabled={isSubmitting}
                                className={`ml-auto px-6 py-2 bg-sky-500 text-white rounded-lg transition-opacity font-inter ${
                                    isSubmitting ? "opacity-50 cursor-not-allowed" : ""
                                }`}
                            >
                                {isSubmitting ? "Creating..." : "Create Advertisement"}
                            </button>
                        )}
                    </div>

                </div>
            </div>
        </div>
    );
};

export default BuyAdvertisementForm;
