import React, {useState, useRef, useCallback, useEffect} from "react";
import {FiEye, FiEyeOff, FiUpload, FiMail, FiLock, FiUser, FiArrowLeft} from "react-icons/fi";
import { useDropzone } from 'react-dropzone';
import {login, sendPinToEmail, signupNewAccount, uploadImage} from "../../api/apis";
import {useMainContext} from "../MainContext";
import {useSnackbar} from "../SnackBarContext";

const DEFAULT_PROFILE_PHOTO = "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e";

const LoginSignupPage = () => {
    const {
        onSuccessfulLogin
    } = useMainContext();

    const { showSnackbar } = useSnackbar(); // Use snackbar context
    const [formStage, setFormStage] = useState(1);
    const [isSignUp, setIsSignUp] = useState(false);
    const [showPassword, setShowPassword] = useState(false);
    const [previewImageUrl, setPreviewImageUrl] = useState<string | null>(null);
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        pin: ["", "", "", "", "", ""],
        nickname: "",
        avatar: "",
        acceptedTerms: false
    });
    const [errors, setErrors] = useState({});
    const [timer, setTimer] = useState(60);

    const pinRefs = useRef([]);

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };

    // Function to handle login
    const handleLogin = async () => {
        const pinString = formData.pin.join('');

        try {
            const response = await login({
                username: formData.email,
                password: formData.password,
                pin: pinString
            }, showSnackbar);

            showSnackbar('Successfully Logged in!', 'success'); // Show error using SnackbarContext
            onSuccessfulLogin();
        } catch (error) {
            showSnackbar('' + error, 'error'); // Show error using SnackbarContext
        }
    };

    // Function to handle signup
    const handleSignup = async () => {
        const pinString = formData.pin.join('');

        try {
            const data = await signupNewAccount({
                username: formData.email,
                password: formData.password,
                pin: pinString,
                avatar: formData.avatar === "" ? DEFAULT_PROFILE_PHOTO : formData.avatar,
                nickname: formData.nickname
            }, showSnackbar);

            // Show success notification
            showSnackbar('Successfully Signed up!', 'success');
            onSuccessfulLogin();
        } catch (error) {
            // Show error returned from API
            showSnackbar('' + error, 'error'); // Show error using SnackbarContext
        }
    };

    const completeSignUpOrLogin = async () => {
        if (formStage === 2 && !isSignUp) { // only 2 steps to login
            return handleLogin()
        }

        if (formStage === 3 && isSignUp) { // 3 steps to signup
            return handleSignup();
        }
    };

    const validatePassword = (password) => {
        //const hasUpper = /[A-Z]/.test(password);
        //const hasLower = /[a-z]/.test(password);
        const hasNumber = /\d/.test(password);
        //const hasSpecial = /[!@#$%^&*]/.test(password);
        const isLengthValid = password.length >= 8;
        //return { hasUpper, hasLower, hasNumber, hasSpecial, isLengthValid };
        return { hasNumber, isLengthValid };
    };

    // 🔹 Drag-and-Drop Handler
    const onDrop = useCallback(async (acceptedFiles: File[]) => {
        const file = acceptedFiles[0];
        if (!file) return;

        // Generate a preview using URL.createObjectURL
        setPreviewImageUrl(URL.createObjectURL(file));

        // Upload the image
        const uploadedFilePath = await uploadImage(file, showSnackbar);
        console.log("uploadedFile", uploadedFilePath);

        // Store the uploaded file URL
        setFormData(prevData => ({
            ...prevData,
            avatar: uploadedFilePath, // Use the actual uploaded file URL
        }));

        console.log("uploadedFile in formdata", formData);

        showSnackbar("File upload success!", "success");
    }, []);

    // 🔹 Dropzone Setup
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: { "image/*": [".jpeg", ".jpg", ".png"] },
        maxSize: 2 * 1024 * 1024, // 2MB limit
    });

    const handlePinChange = (index, value, event) => {
        if (value.length <= 1 && /^\d*$/.test(value)) {
            const newPin = [...formData.pin];
            newPin[index] = value;
            setFormData(prev => ({ ...prev, pin: newPin }));

            // Move to the next input if a digit is entered
            if (value && index < formData.pin.length - 1) {
                pinRefs.current[index + 1].focus();
            }
        }

        // Detect backspace on an empty input and move focus back
        if (event.key === "Backspace" && !value && index > 0) {
            pinRefs.current[index - 1].focus();
        }
    };

    const handleSendPin = async () => {
        // Reset timer and trigger pin sending logic
        setTimer(60);
        // Add your pin sending logic here
        try {
            await sendPinToEmail(
                {
                    email:formData.email
                }, showSnackbar);
            showSnackbar('Pin number has been sent to the given email address!', 'success');
        } catch (error) {
            showSnackbar('' + error, 'error'); // Show error using SnackbarContext
        }
    };

    useEffect(() => {
        let interval: NodeJS.Timeout;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(prev => prev - 1);
            }, 1000);
        }

        return () => clearInterval(interval);
    }, [timer]);

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.acceptedTerms && isSignUp) {
            setErrors(prev => ({ ...prev, terms: "You must accept the terms and conditions" }));
            return;
        }
        if (formStage < (isSignUp ? 3 : 2)) {
            setFormStage(prev => prev + 1);
        }
    };

    const handleBack = () => {
        if (formStage > 1) {
            setFormStage(prev => prev - 1);
        }
    };

    const renderPasswordStrength = () => {
        const strength = validatePassword(formData.password);
        return (
            <div className="mt-2 space-y-1">
                <div className={`text-xs ${strength.isLengthValid ? "text-green-500" : "text-gray-400"}`}>✓ Be at least 8 characters long</div>
                {/*<div className={`text-xs ${strength.hasUpper ? "text-green-500" : "text-gray-400"}`}>✓ One uppercase letter</div>*/}
                {/*<div className={`text-xs ${strength.hasLower ? "text-green-500" : "text-gray-400"}`}>✓ One lowercase letter</div>*/}
                <div className={`text-xs ${strength.hasNumber ? "text-green-500" : "text-gray-400"}`}>✓ Include at least 1 number</div>
                 {/*<div className={`text-xs ${strength.hasSpecial ? "text-green-500" : "text-gray-400"}`}>✓ One special character</div>*/}
            </div>
        );
    };

    // 🔥 Automatically call the API when `formStage === 2`
    useEffect(() => {
        if ((formStage === 2 && !isSignUp) || (formStage === 3 && isSignUp)) {
            handleSendPin();
        }
    }, [formStage]);

    return (
        <div className={`min-h-screen bg-gray-50 text-gray-900 mt-16`}>
            <div className="container mx-auto px-4 py-8 max-w-md">
                <div className="bg-white rounded-lg shadow-xl p-8">
                    <div className="flex justify-between items-center mb-8">
                        <div className="flex items-center gap-4">
                            {formStage > 1 && (
                                <button
                                    onClick={handleBack}
                                    className="p-2 rounded-full hover:bg-gray-200"
                                >
                                    <FiArrowLeft className="text-xl"/>
                                </button>
                            )}
                            <h1 className="text-2xl font-bold">{isSignUp ? "Create Account" : "Sign In"}</h1>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit} className="space-y-6">
                        {formStage === 1 && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium mb-1">Email</label>
                                    <div className="relative">
                                        <FiMail className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                        <input
                                            type="email"
                                            className="w-full pl-10 pr-4 py-2 border rounded-lg"
                                            value={formData.email}
                                            onChange={(e) => setFormData(prev => ({ ...prev, email: e.target.value }))}
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">Password</label>
                                    <div className="relative">
                                        <FiLock className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" />
                                        <input
                                            type={showPassword ? "text" : "password"}
                                            className="w-full pl-10 pr-12 py-2 border rounded-lg"
                                            value={formData.password}
                                            onChange={(e) => setFormData(prev => ({ ...prev, password: e.target.value }))}
                                            required
                                        />
                                        <button
                                            type="button"
                                            className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                                            onClick={() => setShowPassword(!showPassword)}
                                        >
                                            {showPassword ? <FiEyeOff /> : <FiEye />}
                                        </button>
                                    </div>
                                    {isSignUp && renderPasswordStrength()}
                                </div>

                                {isSignUp && (
                                    <div className="space-y-2">
                                        <div className="flex items-start">
                                            <div className="flex items-center h-5">
                                                <input
                                                    id="terms"
                                                    type="checkbox"
                                                    className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-purple-300"
                                                    checked={formData.acceptedTerms}
                                                    onChange={(e) => {
                                                        setFormData(prev => ({ ...prev, acceptedTerms: e.target.checked }));
                                                        if (e.target.checked) {
                                                            setErrors(prev => ({ ...prev, terms: null }));
                                                        }
                                                    }}
                                                />
                                            </div>
                                            <label htmlFor="terms" className="ml-2 text-sm text-gray-600">
                                                I agree to the <a href="/termsOfService" className="text-purple-600 hover:underline">Terms of Service </a>
                                                and <a href="/privacyPolicy" className="text-purple-600 hover:underline">Privacy Policy</a>
                                            </label>
                                        </div>
                                        {errors.terms && <p className="text-red-500 text-xs">{errors.terms}</p>}
                                    </div>
                                )}
                            </div>
                        )}

                        {formStage === 2 && isSignUp && (
                            <div className="space-y-4">
                                <div>
                                    <label className="block text-sm font-medium mb-1">Nickname</label>
                                    <div className="relative">
                                        <FiUser
                                            className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                                        <input
                                            type="text"
                                            className="w-full pl-10 pr-4 py-2 border rounded-lg"
                                            value={formData.nickname}
                                            onChange={(e) => setFormData(prev => ({ ...prev, nickname: e.target.value }))}
                                            required
                                        />
                                    </div>
                                </div>

                                <div>
                                    <label className="block text-sm font-medium mb-1">Profile Picture</label>
                                    <div
                                        {...getRootProps()}
                                        className="border-2 border-dashed rounded-lg p-4 text-center cursor-pointer hover:border-indigo-500"
                                    >
                                        <input {...getInputProps()} />
                                        <FiUpload className="mx-auto text-3xl mb-2" />
                                        <p className="text-sm text-gray-500">Drag & drop or click to upload</p>
                                    </div>

                                    {/* Show preview if available */}
                                    {previewImageUrl && (
                                        <img src={previewImageUrl} alt="Preview" className="mt-2 w-40 h-40 object-cover" />
                                    )}

                                    {formData.avatar && (
                                        <div className="mt-4">
                                            <img
                                                src={formData.avatar}
                                                alt="Preview"
                                                className="w-24 h-24 rounded-full mx-auto object-cover"
                                            />
                                        </div>
                                    )}
                                </div>
                            </div>
                        )}

                        {((formStage === 2 && !isSignUp) || (formStage === 3 && isSignUp)) && (
                            <div className="space-y-4">
                                <div>
                                    {/*
                                    <label className="block text-sm font-medium mb-1">Verification PIN sent
                                        to {formData.email}</label>
                                         */}
                                    <div className="flex gap-2 justify-between">
                                        {formData.pin.map((digit, index) => (
                                            <input
                                                key={index}
                                                type="tel"
                                                pattern="[0-9]*"
                                                inputMode="numeric"
                                                maxLength="1"
                                                ref={el => pinRefs.current[index] = el}
                                                className="w-12 h-12 text-center border rounded-lg"
                                                value={digit}
                                                onChange={(e) => handlePinChange(index, e.target.value, e)}
                                                onKeyDown={(e) => handlePinChange(index, "", e)} // Detect Backspace                                                required
                                            />
                                        ))}
                                    </div>
                                    {/* Reminder to check junk */}
                                    <div
                                        className="bg-indigo-50 rounded-lg p-4 mt-4 text-sm text-blue-800">
                                        <p>
                                            We’ve sent a verification PIN to{" "}
                                            <span className="text-blue-700 font-bold">
                                                {formData.email}
                                            </span>
                                            . If you don’t see it, please check your spam or junk folder.
                                        </p>
                                    </div>
                                    <div className="flex justify-between items-center mt-2">
                                        <p className="text-sm text-gray-500">Resend code in {timer}s</p>
                                        <button
                                            type="button"
                                            onClick={handleSendPin}
                                            className="text-indigo-600 hover:text-indigo-700 text-sm font-medium"
                                            disabled={timer > 0}>
                                            Send PIN
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )}

                        <button
                            onClick={completeSignUpOrLogin} // Call the signup function when clicked
                            type="submit"
                            className="w-full bg-indigo-600 text-white py-2 rounded-lg hover:bg-indigo-700 transition-colors"
                        >
                            {formStage === (isSignUp ? 3 : 2) ? "Submit" : "Continue"}
                        </button>
                    </form>

                    <p className="mt-4 text-center text-sm">
                        {isSignUp ? "Already have an account? " : "Don't have an account? "}
                        <button
                            type="button"
                            className="text-purple-600 hover:underline"
                            onClick={() => {
                                setIsSignUp(!isSignUp);
                                setFormStage(1);
                            }}
                        >
                            {isSignUp ? "Sign In" : "Sign Up"}
                        </button>
                    </p>
                </div>
            </div>
        </div>
    );
};

export default LoginSignupPage;