import axios, { AxiosInstance } from 'axios';

// Create an Axios instance
const apiClient: AxiosInstance = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL || 'http://localhost:3000',
    headers: {
        'Content-Type': 'application/json',
    },
});

const UNAUTHORIZED_ERROR_MESSAGE = 'Unauthorized access, Please login and try again';

// Set up an interceptor to handle responses
apiClient.interceptors.response.use(
    response => response, // If response is successful, just return it
    error => {
        if (error.response) {
            const status = error.response.status; // Check for HTTP status
            const errorMessage = error.response.data?.errorMessage || 'An error occurred';

            if (status === 401) {
                // Return custom error object for 401
                return Promise.reject({ status, message: UNAUTHORIZED_ERROR_MESSAGE });
            } else if (status === 403) {
                // Return custom error object for 403
                return Promise.reject({ status, message: UNAUTHORIZED_ERROR_MESSAGE });
            } else if (status === 1000) {
                return Promise.reject({ status, message: "Username already taken"});
            } else {
                console.error(`Error ${status}: ${errorMessage}`);
                // Return custom error object for other errors
                return Promise.reject({ status, message: errorMessage });
            }
        }
        // Fallback for network or other errors
        return Promise.reject({ status: 500, message: 'Network error' });
    }
);

export default apiClient;
