import React, { useState, useEffect } from "react";
import {FiFlag, FiSend} from "react-icons/fi";
import {format} from "date-fns";
import {createOrder, getAdByPublicAdId, getAdComments, submitAdComment} from "../../api/apis";
import {useMainContext} from "../MainContext";
import {useParams} from "react-router-dom";
import {useSnackbar} from "../SnackBarContext";
import {AdCommentRes, Advertisement, SubmitAdCommentRequest} from "../../type/types";
import dayjs from "dayjs";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";

const OrderDetails = () => {
    const {
        adDetails, // Not in use for now
    } = useMainContext();

    const {publicAdId} = useParams(); // Get ID from URL
    const { showSnackbar } = useSnackbar();
    const [ad, setAd] = useState<Advertisement>();
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const pageSize = 10; // Number of comments per page
    const [comments, setComments] = useState<AdCommentRes[]>([]);
    const [newComment, setNewComment] = useState('');
    const [showConfirmation, setShowConfirmation] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [refreshKey, setRefreshKey] = useState(0); // 🔥 Track new updates

    const DEFAULT_PROFILE_PHOTO = "https://images.unsplash.com/photo-1472099645785-5658abf4ff4e";

    // Fetch ad details with publicAdId
    useEffect(() => {
        const fetchAd = async () => {
            if (publicAdId) {
                try {
                    const response = await getAdByPublicAdId(publicAdId, showSnackbar);
                    setAd(response);
                } catch (error) {
                    const errorMsg = error.message;
                    showSnackbar(errorMsg, "error");
                }
            }
        };
        fetchAd();
    }, [publicAdId]); // Include dependencies


    const handleAcceptOrder = async () => {
        if (!adDetails) {
            showSnackbar('Error creating order', 'error');
        }

        try {
            await createOrder({publicAdId}, showSnackbar);
            showSnackbar('Order created!', 'success');
        } catch (error) {
            const errorMsg = error.message;
            showSnackbar(errorMsg, "error");
        }
    };

    const fetchComments = async () => {
        setIsLoading(true);
        try {
            const result = await getAdComments(publicAdId, page, pageSize, showSnackbar);

            if (result) {
                setComments(result.content); // ✅ Always update with latest comments
                setHasMore(result.totalPages > page);
            }
        } catch (error) {
            const errorMsg = error.message;
            showSnackbar(errorMsg, "error");
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchComments();
    }, [publicAdId, page, refreshKey]); // ✅ Triggers a fresh fetch

    const handleCommentSubmit = async () => {
        if (!newComment.trim()) return;

        const submitAdCommentRequest: SubmitAdCommentRequest = {
            publicAdId,
            comment: newComment,
        };

        try {
            await submitAdComment(submitAdCommentRequest, showSnackbar);
            setNewComment('');
            showSnackbar("Comment submitted successfully", "success");

            // ✅ Force full refresh by changing the `refreshKey`
            setRefreshKey((prev) => prev + 1);
        } catch (error) {
            showSnackbar("Failed to submit comment", "error");
        }
    };

    const totalCost = ad?.price + ad?.travellerFee;

    const ConfirmationModal = ({ isOpen, onClose, onConfirm }) => {
        if (!isOpen) return null;

        const handleConfirmOrder = () => {
            onConfirm(); // Call the function passed as onConfirm (e.g., handleAcceptOrder)
            handleAcceptOrder();
            onClose(); // Close the modal after handling the order
        };

        return (
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="bg-white rounded-lg p-6 max-w-md w-full">
                    <h3 className="text-xl font-bold mb-4">Confirm Before You Accept</h3>
                    <p className="mb-6">Make sure you've checked all details with the Lootie before accepting this quest. Once you accept, the order cannot be canceled. Ready to proceed?</p>
                    <div className="flex justify-end gap-4">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gray-200 rounded-lg hover:bg-gray-300 transition-colors"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={handleConfirmOrder}
                            className="px-4 py-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors"
                        >
                            Confirm
                        </button>
                    </div>
                </div>
            </div>
        );
    };

    if (isLoading) {
        return <div className="flex items-center justify-center min-h-screen">Loading...</div>;
    }

    return (
        <div className="max-w-7xl mx-auto px-4 py-8 bg-gray-50 min-h-screen mt-16">
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {/* Product Details Section */}
                <div className="space-y-6">
                    <div className="bg-white rounded-lg shadow-md overflow-hidden">
                        <img
                            src={ad?.imageUrl}
                            alt="Product"
                            className="w-full h-64 object-contain"
                        />
                        <div className="p-6">
                            <h1 className="font-medium text-gray-700 mb-4">{ad?.description}</h1>

                            <div className="flex items-center mb-3 space-x-1">
                                <CalendarMonthOutlinedIcon fontSize="small" className="mr-1"/>
                                <span>{format(new Date(ad.startTime), "dd MMM yy")}</span>
                                <span> - </span>
                                <span>{format(new Date(ad.endTime), "dd MMM yy")}</span>
                            </div>
                            <div className="flex items-center">
                            <FiFlag fontSize="medium" className="mr-3"/>
                                {ad.sourceCountry}
                            </div>
                        </div>
                    </div>

                    {/* Price Breakdown Section */}
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-xl font-bold mb-4">Price Breakdown</h2>
                        <div className="space-y-3">
                            <div className="flex justify-between text-gray-600">
                                <span>Product Price</span>
                                <span>${ad?.price.toFixed(2)}</span>
                            </div>
                            <div className="flex justify-between text-gray-600">
                                <span>Wanderer's Reward</span>
                                <span>${ad?.travellerFee.toFixed(2)}</span>
                            </div>
                            <div className="flex justify-between text-gray-600">
                                <div>
                                    <span>Platform Fee</span>
                                    <span
                                        className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-xs ml-2">Waived
                                        for now!
                                    </span>
                                </div>
                                <span>$0</span>
                            </div>
                            <div className="border-t pt-3">
                                <div className="flex justify-between font-bold text-lg">
                                <span>Total</span>
                                    <span className="text-green-600">${totalCost.toFixed(2)}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Comments Section */}
                <div className="space-y-6">
                    <div className="bg-white rounded-lg shadow-md p-6">
                        <h2 className="text-xl font-bold mb-4">Comments</h2>
                        <div className="flex gap-2 mb-6">
                            <input
                                type="text"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                placeholder="Add a comment..."
                                className="flex-1 p-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                            <button
                                onClick={handleCommentSubmit}
                                disabled={!newComment.trim()}
                                className="p-2 bg-indigo-600 text-white rounded-lg hover:bg-indigo-700 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                                <FiSend className="w-5 h-5"/>
                            </button>
                        </div>
                        <div className="space-y-4 max-h-96 overflow-y-auto">
                            {comments.map((comment) => (
                                <div key={comment.createdDate} className="flex gap-4">
                                    <img
                                        src={comment.avatar}
                                        alt={comment.nickname}
                                        className="w-10 h-10 rounded-full"
                                    />
                                    <div>
                                        <div className="flex items-center gap-2">
                                            <span className="font-medium">{comment.nickname}</span>
                                            <span className="text-sm text-gray-500">
                        {comment.createdDate ? format(new Date(comment.createdDate * 1000), "MMM dd, yyyy 'at' h:mm a") : "Invalid date"}
                      </span>
                                        </div>
                                        <p className="text-gray-700">{comment.comment}</p>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    <button
                        onClick={() => setShowConfirmation(true)}
                        disabled={ad?.status !== 1} // ✅ Disable if ad.status is not "new" (1)
                        className={`w-full py-3 rounded-lg font-medium transition-colors ${
                            ad?.status === 1
                                ? "bg-indigo-600 text-white hover:bg-indigo-700"
                                : "bg-gray-300 text-gray-500 cursor-not-allowed opacity-50"
                        }`}
                    >
                        Confirm Order
                    </button>

                </div>
            </div>

            <ConfirmationModal
                isOpen={showConfirmation}
                onClose={() => setShowConfirmation(false)}
                onConfirm={() => {
                    setShowConfirmation(false);
                }}
            />
        </div>
    );
};

export default OrderDetails;