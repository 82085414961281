import apiClient from "./ExceptionInterceptor";

interface ApiResponse<T> {
    code: number;
    errorMessage?: string;
    data: T;
}

// Centralized API function
export async function apiRequest<T>(
    method: 'get' | 'post' | 'put' | 'delete',
    url: string,
    data?: any,
    showSnackbar?: (message: string, severity?: 'error' | 'success') => void,
    requiresAuth: boolean = true, // Default: requires authentication
    params?: Record<string, any> // NEW: Optional query parameters
): Promise<T | null> {
    try {
        // 🟢 Detect if `data` is FormData
        const isFormData = data instanceof FormData;

        // 🔹 Prepare headers dynamically
        const headers: Record<string, string> = {};

        // Attach token if required
        if (requiresAuth) {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                headers['Authorization'] = `Bearer ${token}`;
            }
        }

        // 🔹 Only set Content-Type for JSON requests (DO NOT set it for FormData)
        if (!isFormData) {
            headers["Content-Type"] = "application/json";
        }

        // Make the API call
        const response = await apiClient.request<ApiResponse<T>>({
            method,
            url,
            data, // POST/PUT body
            params, // NEW: Query parameters for GET requests
            headers,
            ...(isFormData ? { transformRequest: (data) => data } : {}), // 🔹 Only apply for FormData
        });

        // Handle non-200 responses
        if (response.data.code !== 200) {
            showSnackbar && showSnackbar(response.data.errorMessage || 'Error occurred', 'error');
            throw new Error(response.data.errorMessage);
        }

        return response.data.data;
    } catch (error: any) {
        const errorMessage = error.response?.data?.errorMessage || error.message || 'An error occurred';
        showSnackbar && showSnackbar(errorMessage, 'error');
        // THROW the error for the caller to handle
        throw new Error(errorMessage);
    }
}
