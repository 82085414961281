import React, {createContext, useContext, useState, ReactNode, useEffect} from 'react';
import {Advertisement, MainContextProps} from "../type/types";
import {useNavigate} from "react-router-dom";
import {checkLoginStatus} from "./auth/util/authUtils";

// Create the context
export const MainContext = createContext<MainContextProps | undefined>(undefined);

// Context provider component
export const MainContextProvider = ({ children }: { children: ReactNode }) => {
    const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
    const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
    const [isCreateOrderModalOpen, setIsCreateOrderModalOpen] = useState(false);
    const [adDetails, setAdDetails] = useState<Advertisement | null>(null);
    const [isLoggedIn, setIsLoggedIn] = useState(checkLoginStatus());
    const navigate = useNavigate();

    // Update login status whenever the token changes
    useEffect(() => {
        const handleStorageChange = () => {
            setIsLoggedIn(checkLoginStatus());
        };

        window.addEventListener("storage", handleStorageChange);
        return () => window.removeEventListener("storage", handleStorageChange);
    }, []);

    // Periodic token expiry check
    useEffect(() => {
        const interval = setInterval(() => {
            if (!checkLoginStatus()) {
                localStorage.removeItem("jwtToken"); // Ensure token is removed
                setIsLoggedIn(false);
                alert("Session expired, please login again to continue");
            }
        }, 600000); // Check every 10 minute

        return () => clearInterval(interval);
    }, [navigate]);

    const onClickCloseLogin = () => {
    };

    const onClickLogin = () => {
        navigate('/login');
    };

    const onRedirectToSignup = () => {
        setIsSignupModalOpen(true);
        setIsLoginModalOpen(false);
    }

    const onClickCloseSignup = () => {
        setIsSignupModalOpen(false)
    }

    const adForCreateOrderEvent = (ad: Advertisement) => {
        setAdDetails(ad); // Not in use for now
        navigate(`/createOrder/${ad.publicAdId}`);
    };

    const closeCreateOrderModalEvent = () => {
        setIsCreateOrderModalOpen(false);
    }

    const onSuccessfulLogin = () => {
        setIsLoggedIn(true);
        navigate('/home');
    }

    const onClickLogout = () => {
        localStorage.removeItem('jwtToken'); // or sessionStorage.removeItem('token');
        setIsLoggedIn(false);
        navigate('/home'); // Redirect to the homepage
    }

    return (
        <MainContext.Provider value={
            {
                isLoginModalOpen,
                onClickCloseLogin,
                onClickLogin,
                onRedirectToSignup,
                isSignupModalOpen,
                onClickCloseSignup,
                adForCreateOrderEvent,
                isCreateOrderModalOpen,
                closeCreateOrderModalEvent,
                adDetails,
                isLoggedIn,
                onSuccessfulLogin,
                onClickLogout
            }
        }>
            {children}
        </MainContext.Provider>
    );
};

// Custom hook to safely use the context
export const useMainContext = (): MainContextProps => {
    const context = useContext(MainContext);
    if (!context) {
        throw new Error('useMainContext must be used within a MainContextProvider');
    }
    return context;
};