import React, { useState, useEffect } from "react";
import {FiSearch, FiX, FiChevronDown, FiInfo, FiFlag} from "react-icons/fi";
import {  FaShoppingCart, FaStore } from "react-icons/fa";
import {fetchAdvertisements} from "../../api/apis";
import {Advertisement} from "../../type/types";
import {useSnackbar} from "../SnackBarContext";
import {adTypeMap} from "../../enum/enums";
import {countries} from "../../Countries";
import {useMainContext} from "../MainContext";
import {format} from "date-fns";
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import EmojiEventsOutlinedIcon from '@mui/icons-material/EmojiEventsOutlined';

const ADS_PER_PAGE = 4;
const BUY_AD_TAG = "Buy";
const SELL_AD_TAG = "Sell";

const MARKETPLACE_DEMARKER_TITLE = "Browse Lootie Quests – Find & Deliver Exclusive Items!";
const MARKETPLACE_DEMARKER_BODY = "Connect with travelers and ship your desired items globally";

const ProductMarketplace = () => {
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedCountry, setSelectedCountry] = useState("");
    const [adType, setAdType] = useState(adTypeMap.all);
    const [currentPage, setCurrentPage] = useState(1);
    const [advertisements, setAdvertisements] = useState<Advertisement[]>([]);
    const { showSnackbar } = useSnackbar(); // Use snackbar context
    const [totalPages, setTotalPages] = useState(1);
    const { adForCreateOrderEvent } = useMainContext();

    // Fetch advertisements from the backend API
    const loadAdvertisements = async () => {
        try {
            // Subtract 1 from currentPage because backend is 0-indexed
            const response = await fetchAdvertisements(currentPage-1, ADS_PER_PAGE, showSnackbar); // Fetch the new ads

            if (!response) {
                showSnackbar('Failed to load advertisements:', 'error');
                return;
            }

            // Filter out ads that already exist in the current state of 'advertisements'
            const newAds = response.content.filter(
                (newAd) => !advertisements.some((existingAd) => existingAd.id === newAd.id)
            );

            // Append only new ads to both 'advertisements' and 'filteredAds'
            setAdvertisements((prevAds) => {
                const uniqueAds = [...prevAds, ...newAds];
                return [...new Map(uniqueAds.map(ad => [ad.id, ad])).values()]; // Ensures no duplicates
            });

            setTotalPages(response.totalPages);
        } catch (error) {
            showSnackbar('Failed to load advertisements:', 'error');
        }
    };

    // Fetch ads when page changes or filters are applied
    useEffect(() => {
        loadAdvertisements();
    }, [currentPage]);

    const filteredAds = advertisements.filter(ad => {
        const matchesSearch = ad.description.toLowerCase().includes(searchQuery.toLowerCase());
        const matchesCountry = !selectedCountry || ad.sourceCountry === selectedCountry;
        const matchesType = adType === adTypeMap.all || adType === ad.adType;
        return matchesSearch && matchesCountry && matchesType;
    });

    const acceptOrder = (ad: Advertisement) => {
        adForCreateOrderEvent(ad);
    };

    const ProductCard = ({ ad }) => (
        <div
            className="bg-white rounded-lg shadow-md overflow-hidden transition-transform hover:transform hover:scale-105">
            <div className="relative h-60">
                <img
                    src={ad.imageUrl}
                    alt={ad.name}
                    className="w-full h-full object-cover"
                    onError={(e) => {
                        e.target.src = "https://images.unsplash.com/photo-1560393464-5c69a73c5770";
                    }}
                    loading="lazy"
                />
                <div></div>
                <span
                    className={`absolute top-4 right-4 px-3 py-1 rounded-full text-sm font-semibold ${ad.adType === adTypeMap.buy ? "bg-sky-500 text-white" : "bg-teal-500 text-white"}`}
                >
          {ad.adType === adTypeMap.buy ? BUY_AD_TAG : SELL_AD_TAG}
        </span>
            </div>
            <div className="p-4">
                {/*
                <div className="flex items-center justify-between mb-2">
                    <h3 className="text-sm font-bold line-clamp-1 min-h-[1.5rem]">
                        {ad.name}
                    </h3>
                </div>
                */}
                <p className="text-gray-800 mb-3 line-clamp-2 min-h-[2.5rem] text-sm font-bold">{ad.description}</p>
                <div className="space-y-2">
                    <div className="flex justify-between items-center">
                        <div className="text-green-500 font-bold">${ad.price}</div>
                        <button
                            className="bg-indigo-500 hover:bg-indigo-600 text-white px-3 py-1 text-sm rounded-md transition-colors"
                            onClick={() => acceptOrder(ad)}>
                            View details
                        </button>
                    </div>
                    <div className="flex items-center text-sm space-x-2">
                        <div className="text-gray-500">
                        <EmojiEventsOutlinedIcon fontSize="small" className="mr-2"/>
                            <span>
                                Wanderer's Reward: ${ad.price}
                            </span>
                        </div>
                    </div>
                    {/*
                    <div className="flex justify-between items-center text-sm">
                        <div className="text-gray-500">Platform Fee: $0</div>
                        <div
                            className="bg-yellow-100 text-yellow-800 px-2 py-1 rounded-full text-xs">Waived
                            for now!
                        </div>
                    </div>
                    */}
                    <div className="flex items-center space-x-2 text-sm text-gray-500">
                        <CalendarMonthOutlinedIcon fontSize="small" />
                        <span>{format(new Date(ad.startTime), "dd MMM yy")}</span>
                        <span>-</span>
                        <span>{format(new Date(ad.endTime), "dd MMM yy")}</span>
                    </div>
                </div>
            </div>
            <div className="flex justify-between items-center text-sm text-gray-500 border-t pt-2 mb-4">
                <div>
                    <span className="ml-3">
                        Posted by <span className="font-bold text-purple-400">{ad.userNickname}</span>
                    </span>
                </div>
                <div className="flex items-center text-gray-500 mr-5">
                    <FiFlag className="mr-1"/>
                    {ad.sourceCountry}
                </div>
            </div>
        </div>
    );

    return (
        <div className="min-h-screen bg-gray-100 p-4 md:p-8 mt-16">
            <div className="max-w-7xl mx-auto">

                {/* banner to make marketplace section more distinct */}
                <div className="bg-indigo-300 rounded-t-lg p-8 text-center">
                    <h2 className="text-2xl font-bold text-white mb-2">{MARKETPLACE_DEMARKER_TITLE}</h2>
                    <p className="text-white/80">{MARKETPLACE_DEMARKER_BODY}</p>
                </div>

                <div className="bg-white rounded-lg shadow-md p-6 mb-8">
                    <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <div className="relative">
                            <FiSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                            <input
                                type="text"
                                placeholder="Search products..."
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                                className="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            />
                            {searchQuery && (
                                <button
                                    onClick={() => setSearchQuery("")}
                                    className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400 hover:text-gray-600"
                                >
                                    <FiX/>
                                </button>
                            )}
                        </div>

                        <div className="relative">
                            <select
                                value={selectedCountry}
                                onChange={(e) => setSelectedCountry(e.target.value)}
                                className="w-full px-4 py-2 border rounded-lg appearance-none focus:outline-none focus:ring-2 focus:ring-indigo-500"
                            >
                                <option value="">Select Country</option>
                                {countries.map((country) => (
                                    <option key={country} value={country}>
                                        {country}
                                    </option>
                                ))}
                            </select>
                            <FiChevronDown
                                className="absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-400"/>
                        </div>

                        <div className="flex space-x-2">
                            <button
                                onClick={() => setAdType(adTypeMap.all)}
                                className={`flex-1 py-2 rounded-lg ${adType === adTypeMap.all ? "bg-indigo-600 text-white" : "bg-gray-200 text-gray-700"}`}
                            >
                                All Ads
                            </button>
                            <button
                                onClick={() => setAdType(adTypeMap.buy)}
                                className={`flex-1 py-2 rounded-lg ${adType === adTypeMap.buy ? "bg-sky-600 text-white" : "bg-gray-200 text-gray-700"}`}
                            >
                                <FaShoppingCart className="inline mr-2"/>
                                {BUY_AD_TAG}
                            </button>
                            <button
                                onClick={() => setAdType(adTypeMap.sell)}
                                className={`flex-1 py-2 rounded-lg ${adType === adTypeMap.sell ? "bg-teal-600 text-white" : "bg-gray-200 text-gray-700"}`}
                            >
                                <FaStore className="inline mr-2"/>
                                {SELL_AD_TAG}
                            </button>
                        </div>
                    </div>
                </div>

                {filteredAds.length === 0 ? (
                    <div className="text-center py-12">
                        <FiInfo className="mx-auto text-gray-400 mb-4" size={48}/>
                        <p className="text-xl text-gray-600">No products found matching your criteria</p>
                    </div>
                ) : (
                    <>
                        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6 mb-8">
                            {/* When rendering the ads, update the slice */}
                            {filteredAds.slice((currentPage - 1) * ADS_PER_PAGE, currentPage * ADS_PER_PAGE).map((ad) => (
                                <ProductCard key={ad.id} ad={ad}/>
                            ))}
                        </div>
                        <div className="flex justify-center items-center space-x-2">
                            <button
                                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                                disabled={currentPage === 1}
                                className="px-4 py-2 rounded-lg bg-indigo-600 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                            >
                                Previous
                            </button>
                            <span className="text-gray-600">
                                Page {currentPage} of {totalPages}
                            </span>
                            <button
                                onClick={() => setCurrentPage((prev) => Math.min(prev + 1, totalPages))}
                                disabled={currentPage === totalPages}
                                className="px-4 py-2 rounded-lg bg-indigo-600 text-white disabled:bg-gray-300 disabled:cursor-not-allowed"
                            >
                                Next
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default ProductMarketplace;