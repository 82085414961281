import React, { useState } from "react";
import { FaShoppingCart, FaChartLine, FaArrowRight, FaQuestionCircle } from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const ListingCreationPage = () => {
    const [isHoveredBuy, setIsHoveredBuy] = useState(false);
    const [isHoveredSell, setIsHoveredSell] = useState(false);
    const navigate = useNavigate();

    return (
        <div className="min-h-screen bg-gradient-to-br from-gray-50 to-gray-100 flex flex-col items-center justify-center p-4 md:p-8">
            <div className="w-full max-w-6xl">
                <div className="text-center mb-12">
                    <h1 className="text-4xl md:text-5xl font-bold text-gray-800 mb-4">Create Your Listing</h1>
                    <p className="text-lg text-gray-600 max-w-2xl mx-auto">Choose the type of listing you want to create. We'll guide you through the process step by step.</p>
                </div>

                <div className="grid md:grid-cols-2 gap-8 px-4">
                    {/* Buy Listing Card */}
                    <div
                        className={`transform transition-all duration-300 ease-in-out ${isHoveredBuy ? "scale-105" : ""}`}
                        onMouseEnter={() => setIsHoveredBuy(true)}
                        onMouseLeave={() => setIsHoveredBuy(false)}
                    >
                        <div className="bg-white rounded-2xl shadow-xl p-8 cursor-pointer hover:shadow-2xl border-2 border-transparent hover:border-sky-500">
                            <div className="flex items-center justify-between mb-6">
                                <div className="p-4 bg-sky-100 rounded-full">
                                    <FaShoppingCart className="w-8 h-8 text-sky-600" />
                                </div>
                                <FaArrowRight className={`w-6 h-6 text-sky-500 transform transition-all duration-300 ${isHoveredBuy ? "translate-x-2" : ""}`} />
                            </div>
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">Create Buy Listing</h2>
                            <p className="text-gray-600 mb-6">Looking to purchase? Create a buy listing to connect with potential sellers. Specify your requirements and budget.</p>
                            <button
                                onClick={() => navigate("/createBuyAd")}
                                className="w-full bg-sky-500 hover:bg-sky-600 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-300">
                                Start Buying
                            </button>
                        </div>
                    </div>

                    {/* Sell Listing Card */}
                    <div
                        className={`transform transition-all duration-300 ease-in-out ${isHoveredSell ? "scale-105" : ""}`}
                        onMouseEnter={() => setIsHoveredSell(true)}
                        onMouseLeave={() => setIsHoveredSell(false)}
                    >
                        <div className="bg-white rounded-2xl shadow-xl p-8 cursor-pointer hover:shadow-2xl border-2 border-transparent hover:border-teal-500">
                            <div className="flex items-center justify-between mb-6">
                                <div className="p-4 bg-teal-100 rounded-full">
                                    <FaChartLine className="w-8 h-8 text-teal-600" />
                                </div>
                                <FaArrowRight className={`w-6 h-6 text-teal-500 transform transition-all duration-300 ${isHoveredSell ? "translate-x-2" : ""}`} />
                            </div>
                            <h2 className="text-2xl font-bold text-gray-800 mb-4">Create Sell Listing</h2>
                            <p className="text-gray-600 mb-6">Ready to sell? Create a sell listing to showcase your offering. Set your terms and connect with buyers.</p>
                            <button
                                onClick={() => navigate("/createSellAd")}
                                className="w-full bg-teal-500 hover:bg-teal-600 text-white font-semibold py-3 px-6 rounded-lg transition-colors duration-300">
                                Start Selling
                            </button>
                        </div>
                    </div>
                </div>

                <div className="mt-12 text-center">
                    <button className="flex items-center justify-center gap-2 mx-auto text-gray-600 hover:text-gray-800 transition-colors duration-300">
                        <FaQuestionCircle className="w-5 h-5" />
                        <span>Need Help?</span>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ListingCreationPage;