import { jwtDecode } from 'jwt-decode';

export interface JwtPayload {
    exp: number; // Expiration time in seconds
}

export const checkLoginStatus = () => {
    const token = localStorage.getItem("jwtToken");
    if (!token) return false;

    try {
        const payload = JSON.parse(atob(token.split(".")[1])); // Decode JWT
        const isExpired = payload.exp * 1000 < Date.now(); // Check if expired
        return !isExpired;
    } catch (e) {
        return false; // Invalid token
    }
};

/**
 * Validate if the email is in a proper format.
 * @param email - The email address to validate.
 * @returns true if valid, false otherwise.
 */
export const isValidEmail = (email: string): boolean => {
    // Check if email is non-empty and matches the regex for a valid email format.
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Matches email pattern
    return emailRegex.test(email);
};

/**
 * Validate if the password meets the security requirements.
 * @param password - The password to validate.
 * @returns true if valid, false otherwise.
 */
export const isValidPassword = (password: string): boolean => {
    // Password must:
    // - Be at least 8 characters
    // - Include at least 1 lowercase, 1 uppercase, 1 number, and 1 special character
    //const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\W_]).{8,}$/;
    //return passwordRegex.test(password);
    return password.length >= 8; // Only checks for minimum 8 characters
};

/**
 * Handle email validation and set error state.
 * @param value - The email input value.
 * @param setError - Function to update the email error state.
 */
export const handleEmailValidation = (
    value: string,
    setError: (error: string) => void
) => {
    if (!isValidEmail(value)) {
        setError('Invalid email format');
    } else {
        setError('');
    }
};

/**
 * Handle password validation and set error state.
 * @param value - The password input value.
 * @param setError - Function to update the password error state.
 */
export const handlePasswordValidation = (
    value: string,
    setError: (error: string) => void
) => {
    if (!isValidPassword(value)) {
        setError('Password must be at least 8 characters.');
    } else {
        setError('');
    }
};