import React, {useEffect, useRef, useState} from "react";
import {FiMenu, FiX, FiUser, FiSettings, FiLogOut, FiLock, FiCreditCard} from "react-icons/fi";
import {useMainContext} from "../MainContext";
import {useNavigate} from "react-router-dom";

const NavigationBar = () => {
    const {
        isLoggedIn,
        onClickLogout,
    } = useMainContext(); // Assuming isLoggedIn tracks the login status

    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const dropdownRef = useRef(null);

    const companyLogo = 'https://xborder-bucket.s3.ap-southeast-1.amazonaws.com/file-upload-image/WanderLoot+Image.jpg';

    const handleLogin = () => {
        navigate('/login');
    };

    const handleLogout = () => {
        setIsDropdownOpen(false);
        onClickLogout();
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setIsDropdownOpen(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <nav className="fixed top-0 w-full bg-white shadow-md z-50">
            <div className="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8">
                <div className="flex justify-between items-center h-16">
                    {/* Left Section - Logo & Company Name */}
                    <div className="flex items-center">
                        <a
                            href="/home"
                            className="flex items-center hover:opacity-80 transition duration-300"
                            //target="_blank" // to open a new tab
                        >
                            <img
                                src={companyLogo}
                                alt="Company Logo"
                                className="h-10 w-10 rounded-full object-cover"
                            />
                            <span className="ml-3 text-xl font-semibold text-gray-800">WanderLoot</span>
                        </a>
                    </div>

                    {/* Center Section - Navigation Links (Desktop) */}
                    <div className="hidden md:flex items-center space-x-8">
                        <a href="/aboutUs" className="text-gray-600 hover:text-gray-900 transition duration-300">About Us</a>
                        <a href="/createListingLandingPage" className="text-gray-600 hover:text-gray-900 transition duration-300">Start Listing</a>
                    </div>

                    {/* Right Section - Auth */}
                    <div className="flex items-center">
                    {!isLoggedIn ? (
                            <button
                                onClick={handleLogin}
                                className="flex items-center px-4 py-2 rounded-md bg-indigo-600 text-white hover:bg-blue-700 transition duration-300"
                                aria-label="Login"
                            >
                                <FiLock className="mr-2"/>
                                Login
                            </button>
                        ) : (
                            <div className="relative" ref={dropdownRef}>
                                <button
                                    onClick={toggleDropdown}
                                    className="flex items-center space-x-2"
                                    aria-label="User menu"
                                >
                                    <img
                                        src={localStorage.getItem('avatar')}
                                        alt="User Avatar"
                                        className="h-8 w-8 rounded-full object-cover"
                                    />
                                </button>

                                {isDropdownOpen && (
                                    <div
                                        className="absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5">
                                        {/*
                                        <a
                                            href="#"
                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            <FiUser className="mr-3"/> My Profile (WIP)
                                        </a>
                                        */}
                                        <a
                                            href="/userPage"
                                            className="flex items-center px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            <FiCreditCard className="mr-3"/> My Transactions
                                        </a>
                                        <button
                                            onClick={handleLogout}
                                            className="flex items-center w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
                                        >
                                            <FiLogOut className="mr-3"/> Logout
                                        </button>
                                    </div>
                                )}
                            </div>
                        )}

                        <button
                            onClick={toggleMenu}
                            className="md:hidden ml-4 text-gray-600 hover:text-gray-900"
                            aria-label="Menu"
                        >
                            {isMenuOpen ? <FiX size={24}/> : <FiMenu size={24}/>}
                        </button>
                    </div>
                </div>

                {isMenuOpen && (
                    <div className="md:hidden py-4">
                        <a href="/home" className="block py-2 text-gray-600 hover:text-gray-900">Home</a>
                        <a href="/aboutUs" className="block py-2 text-gray-600 hover:text-gray-900">About Us</a>
                        <a href="/createListingLandingPage" className="block py-2 text-gray-600 hover:text-gray-900">Start Listing</a>
                    </div>
                )}
            </div>
        </nav>
    );
};

export default NavigationBar;