import React, { createContext, useContext, useState, ReactNode } from 'react';
import { Snackbar, Alert, AlertColor } from '@mui/material';

// Define the types for the Snackbar context
interface SnackbarContextType {
    showSnackbar: (message: string, severity?: AlertColor, autoHideDuration?: number) => void;
}

// Create the Snackbar context with an empty initial value
const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

// Custom hook to use the Snackbar context
export const useSnackbar = (): SnackbarContextType => {
    const context = useContext(SnackbarContext);
    if (!context) {
        throw new Error('useSnackbar must be used within a SnackbarProvider');
    }
    return context;
};

// Define the props for the SnackbarProvider component
interface SnackbarProviderProps {
    children: ReactNode;
}

// SnackbarProvider component that wraps your app and provides the snackbar function
export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
    const [snackbarData, setSnackbarData] = useState<{
        message: string;
        severity: AlertColor;
        open: boolean;
        autoHideDuration?: number;
    }>({
        message: '',
        severity: 'info',
        open: false,
    });

    // Function to open the snackbar
    const showSnackbar = (message: string, severity: AlertColor = 'info', autoHideDuration: number = 3000) => {
        setSnackbarData({ message, severity, open: true, autoHideDuration });
    };

    // Function to close the snackbar
    const closeSnackbar = () => {
        setSnackbarData((prev) => ({ ...prev, open: false }));
    };

    return (
        <SnackbarContext.Provider value={{ showSnackbar }}>
            {children}

            {/* Snackbar component */}
            <Snackbar
                open={snackbarData.open}
                autoHideDuration={snackbarData.autoHideDuration}
                onClose={closeSnackbar}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={closeSnackbar} severity={snackbarData.severity} sx={{ width: '100%' }}>
                    {snackbarData.message}
                </Alert>
            </Snackbar>
        </SnackbarContext.Provider>
    );
};
