import React, {useEffect, useState} from "react";
import { FaShoppingBag, FaPlane, FaArrowRight } from "react-icons/fa";
import {useNavigate} from "react-router-dom";

const WANDERER = 'wanderer';
const LOOTIES = 'looties';

const LOOTIES_GUIDE_TITLE_STEP_1 = "Create a Lootie Quest";
const LOOTIES_GUIDE_DESCRIPTION_STEP_1 = "Need something from overseas? Post a Lootie Quest—a public request for Wanderers (travelers) to bring back your item. Be sure to describe your request clearly to attract the right Wanderer.";
const LOOTIES_GUIDE_TITLE_STEP_2 = "Awaiting Wanderer's Acceptance";
const LOOTIES_GUIDE_DESCRIPTION_STEP_2 = "Once your Lootie Quest is live, Wanderers browsing the marketplace can accept it. Once a Wanderer commits to your quest, it disappears from the marketplace, ensuring only one traveler fulfills your request.";
const LOOTIES_GUIDE_TITLE_STEP_3 = "Finalize the Wanderer’s Reward";
const LOOTIES_GUIDE_DESCRIPTION_STEP_3 = "After a Wanderer accepts your quest, discuss and agree on a Wanderer’s Reward—a small amount you’ll pay them for their time and effort in bringing back your item. Your Lootie quest will not be on the marketplace at this point in time.";
const LOOTIES_GUIDE_TITLE_STEP_4 = "Receive Your WanderLoot";
const LOOTIES_GUIDE_DESCRIPTION_STEP_4 = "Meet your Wanderer at the agreed place and time to receive your item. Once complete, your order is fulfilled, and you’ve successfully looted an exclusive find!";

const WANDERER_GUIDE_TITLE_STEP_1 = "Find a Lootie Quest & Confirm Details";
const WANDERER_GUIDE_DESCRIPTION_STEP_1 = "Looking to earn a little extra while traveling? Browse Lootie Quests—requests from Looties (shoppers) who need items from abroad. Before accepting a quest, use the Lootie Quest thread to ask any questions and confirm item specifics.";
const WANDERER_GUIDE_TITLE_STEP_2 = "Finalising the Wanderer’s Reward";
const WANDERER_GUIDE_DESCRIPTION_STEP_2 = "Once everything is clear, accept the Lootie Quest to officially take it on. The quest will then be removed from the marketplace. At this stage, finalize the Wanderer’s Reward—the amount you’ll earn for bringing back the item.";
const WANDERER_GUIDE_TITLE_STEP_3 = "Purchasing the WanderLoot";
const WANDERER_GUIDE_DESCRIPTION_STEP_3 = "Buy the requested item during your trip and keep the Lootie updated through the thread if needed.";
const WANDERER_GUIDE_TITLE_STEP_4 = "Deliver the Loot & Get Rewarded";
const WANDERER_GUIDE_DESCRIPTION_STEP_4 = "Meet the Lootie at the agreed time and place to complete the exchange. Once the item is handed over, you've successfully helped someone get an exclusive find—while earning a little extra for your travels!";

const UserGuideBanner = () => {
    const [activeTab, setActiveTab] = useState(LOOTIES);
    const [stepColor, setStepColor] = useState("bg-sky-600")
    const navigate = useNavigate();

    useEffect(() => {
        if (activeTab === WANDERER) {
            setStepColor("bg-teal-600");
        } else {
            setStepColor("bg-sky-600")
        }
    }, [activeTab]);

    const buyerSteps = [
        {
            id: 1,
            title: LOOTIES_GUIDE_TITLE_STEP_1,
            description: LOOTIES_GUIDE_DESCRIPTION_STEP_1,
            image: "https://images.unsplash.com/photo-1555421689-491a97ff2040"
        },
        {
            id: 2,
            title: LOOTIES_GUIDE_TITLE_STEP_2,
            description: LOOTIES_GUIDE_DESCRIPTION_STEP_2,
            image: "https://images.unsplash.com/photo-1499244571948-7ccddb3583f1"
        },
        {
            id: 3,
            title: LOOTIES_GUIDE_TITLE_STEP_3,
            description: LOOTIES_GUIDE_DESCRIPTION_STEP_3,
            image: "https://images.unsplash.com/photo-1554224155-8d04cb21cd6c"
        },
        {
            id: 4,
            title: LOOTIES_GUIDE_TITLE_STEP_4,
            description: LOOTIES_GUIDE_DESCRIPTION_STEP_4,
            image: "https://images.unsplash.com/photo-1557821552-17105176677c"
        }
    ];

    const travelerSteps = [
        {
            id: 1,
            title: WANDERER_GUIDE_TITLE_STEP_1,
            description: WANDERER_GUIDE_DESCRIPTION_STEP_1,
            image: "https://images.unsplash.com/photo-1488646953014-85cb44e25828"
        },
        {
            id: 2,
            title: WANDERER_GUIDE_TITLE_STEP_2,
            description: WANDERER_GUIDE_DESCRIPTION_STEP_2,
            image: "https://images.unsplash.com/photo-1436491865332-7a61a109cc05"
        },
        {
            id: 3,
            title: WANDERER_GUIDE_TITLE_STEP_3,
            description: WANDERER_GUIDE_DESCRIPTION_STEP_3,
            image: "https://images.unsplash.com/photo-1527631746610-bca00a040d60"
        },
        {
            id: 4,
            title: WANDERER_GUIDE_TITLE_STEP_4,
            description: WANDERER_GUIDE_DESCRIPTION_STEP_4,
            image: "https://images.unsplash.com/photo-1560472354-b33ff0c44a43"
        }
    ];

    const StepCard = ({ step, index }) => (
        <div className="flex-shrink-0 w-72 bg-white rounded-xl shadow-lg p-6 m-4 transition-transform duration-300 hover:scale-105">
            <div className="relative h-40 mb-4 rounded-lg overflow-hidden">
                <img
                    src={step.image}
                    alt={step.title}
                    className="w-full h-full object-cover"
                    loading="lazy"
                    onError={(e) => {
                        e.target.src = "https://images.unsplash.com/photo-1560472354-b33ff0c44a43";
                    }}
                />
                <div className={`absolute top-2 left-2 ${stepColor} text-white rounded-full w-8 h-8 flex items-center justify-center`}>
                    {index + 1}
                </div>
            </div>
            <h3 className="text-xl font-semibold mb-2">{step.title}</h3>
            <p className="text-gray-600">{step.description}</p>
        </div>
    );

    const handleGetStarted = () => {
        navigate("/createBuyAd"); // Change this to your target path
    }

    return (
        <div className="max-w-7xl mx-auto bg-white rounded-2xl shadow-xl p-6 mb-14">
            <div className="max-w-7xl bg-white rounded-2xl p-6">
                <div className="flex space-x-4 mb-6">
                <button
                        onClick={() => setActiveTab(LOOTIES)}
                        className={`flex items-center px-6 py-3 rounded-lg font-medium transition-colors duration-200 ${
                            activeTab === LOOTIES 
                                ? "bg-sky-600 text-white"
                                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                        }`}
                    >
                        <FaShoppingBag className="mr-2"/>
                        Looties' (Buyer) Guide
                </button>
                <button
                    onClick={() => setActiveTab(WANDERER)}
                    className={`flex items-center px-6 py-3 rounded-lg font-medium transition-colors duration-200 ${
                        activeTab === WANDERER 
                            ? "bg-teal-600 text-white"
                            : "bg-gray-100 text-gray-600 hover:bg-gray-200"
                    }`}
                >
                    <FaPlane className="mr-2"/>
                    Wanderers' (Seller) Guide
                </button>
                </div>

                <div className="relative overflow-x-auto">
                    <div className="flex items-stretch space-x-4 pb-4">
                        {(activeTab === LOOTIES ? buyerSteps : travelerSteps).map(
                            (step, index) => (
                                <StepCard key={step.id} step={step} index={index}/>
                            )
                        )}
                    </div>
                </div>

                <div className="mt-8 flex justify-center">
                    <button
                        onClick={
                            () => {
                                activeTab === LOOTIES ? navigate("/createBuyAd") : navigate("/createSellAd")
                            }
                        }
                        title={activeTab === WANDERER ? "This action is temporarily unavailable for wanderers" : ""}
                        className={`px-8 py-3 rounded-lg flex items-center space-x-2 transition-colors duration-200
                        ${activeTab === LOOTIES ? "bg-sky-600 text-white hover:bg-sky-700" : "bg-teal-600 text-white hover:bg-teal-700"}`}>
                        <span>Get Started</span>
                        <FaArrowRight/>
                    </button>

                </div>
            </div>
        </div>
    );
};

export default UserGuideBanner;