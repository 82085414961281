import {useEffect, useState} from "react";
import { FiMail, FiAlertCircle, FiArrowLeft } from "react-icons/fi";
import { AiOutlineLoading3Quarters } from "react-icons/ai";
import {useSearchParams} from "react-router-dom";
import {useSnackbar} from "../SnackBarContext";

const PasswordResetPage = () => {
    const [email, setEmail] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    const [success, setSuccess] = useState("");
    const [searchParams] = useSearchParams();
    const { showSnackbar } = useSnackbar(); // Use snackbar context
    const token = searchParams.get("token");

    useEffect(() => {
        if (!token) {
            showSnackbar("Invalid or missing reset token.", "error");
        }
    }, [token]);

    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!token) {
            showSnackbar("Invalid or missing reset token.", "error");
            return;
        }

        if (!validateEmail(email)) {
            setError("Please enter a valid email address");
            return;
        }

        setIsLoading(true);
        setError("");

        try {
            // Simulating API call
            await new Promise((resolve) => setTimeout(resolve, 2000));
            setSuccess("Password reset link has been sent to your email");
            setEmail("");
        } catch (err) {
            setError("Failed to send reset link. Please try again.");
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-gray-50 flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md">
                {/*
                <img
                    src="https://images.unsplash.com/photo-1633356122544-f134324a6cee?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3"
                    alt="Company Logo"
                    className="mx-auto h-12 w-auto"
                />
                */}
                <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
                    Reset your password
                </h2>
            </div>

            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                    <form className="space-y-6" onSubmit={handleSubmit}>
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Email address
                            </label>
                            <div className="mt-1 relative rounded-md shadow-sm">
                                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                                    <FiMail className="h-5 w-5 text-gray-400"/>
                                </div>
                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    value={email}
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                        setError("");
                                        setSuccess("");
                                    }}
                                    className={`block w-full pl-10 pr-10 py-3 border ${error ? 'border-red-300' : 'border-gray-300'} rounded-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm`}
                                    placeholder="Enter your registered email"
                                    aria-describedby="email-error"
                                />
                                {!validateEmail(email) && email && (
                                    <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
                                        <FiAlertCircle className="h-5 w-5 text-red-500"/>
                                    </div>
                                )}
                            </div>
                            {error && (
                                <p className="mt-2 text-sm text-red-600" id="email-error">
                                    {error}
                                </p>
                            )}
                            {success && (
                                <p className="mt-2 text-sm text-green-600">{success}</p>
                            )}
                        </div>

                        <button
                            type="submit"
                            disabled={isLoading || !email || !validateEmail(email)}
                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:opacity-50 disabled:cursor-not-allowed"
                        >
                            {isLoading ? (
                                <AiOutlineLoading3Quarters className="h-5 w-5 animate-spin"/>
                            ) : (
                                "Reset Password"
                            )}
                        </button>
                    </form>

                    <div className="mt-6">
                        <div className="relative">
                            <div className="absolute inset-0 flex items-center">
                                <div className="w-full border-t border-gray-300"/>
                            </div>
                            <div className="relative flex justify-center text-sm">
                                <span className="px-2 bg-white text-gray-500">
                                    Need help?
                                    <button className="ml-1 text-indigo-600 hover:text-indigo-500">
                                        Contact Support
                                    </button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PasswordResetPage;