
//const BASE_URL = "http://localhost:8113/xborder/v1";
//const BASE_URL = "http://54.255.200.2:8113/xborder/v1";
const BASE_URL = process.env.REACT_APP_BASE_URL;

const ADS = "advertisement";
const ORDER = "order";
const AUTH = "auth";
const FILE = "file";
const STATIC_CONTENT = "staticContent"
const FEEDBACK = "feedback"

const API_PATHS = {
    GET_ADVERTISEMENTS: `${BASE_URL}/${ADS}/getAds`,
    GET_AD_BY_PUBLIC_AD_ID: `${BASE_URL}/${ADS}/getAd`,
    CREATE_ADVERTISEMENT: `${BASE_URL}/${ADS}/create`,
    GET_USER_ADVERTISEMENTS: `${BASE_URL}/${ADS}/getUserAds`,
    DELETE_ADVERTISEMENT: (id: number | string) => `${BASE_URL}/advertisements/delete/${id}`,
    UPDATE_ADVERTISEMENT: (id: number | string) => `${BASE_URL}/advertisements/update/${id}`,
    SUBMIT_AD_COMMENT: `${BASE_URL}/${ADS}/comment`,
    GET_AD_COMMENTS: `${BASE_URL}/${ADS}/comments`,

    CREATE_ORDER: `${BASE_URL}/${ORDER}/create`,
    GET_USER_ORDER: `${BASE_URL}/${ORDER}/getUserOrders`,

    SIGNUP_NEW_ACCOUNT: `${BASE_URL}/${AUTH}/signup`,
    LOGIN: `${BASE_URL}/${AUTH}/login`,
    SEND_PIN_TO_EMAIL: `${BASE_URL}/${AUTH}/sendPinToEmail`,

    GET_TERMS_OF_SERVICE: `${BASE_URL}/${STATIC_CONTENT}/termsOfService`,
    GET_PRIVACY_POLICY: `${BASE_URL}/${STATIC_CONTENT}/privacyPolicy`,

    UPLOAD_IMAGE: `${BASE_URL}/${FILE}/uploadImage`,

    SUBMIT_FEEDBACK: `${BASE_URL}/${FEEDBACK}`,

    // Add more endpoints as needed
};

export default API_PATHS;
