import React, { useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import { getTermsOfService } from "../../api/apis";
import { useSnackbar } from "../SnackBarContext";

export default function TermsOfServicePage() {
    const [termsContent, setTermsContent] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const { showSnackbar } = useSnackbar();

    useEffect(() => {
        const fetchTerms = async () => {
            try {
                const content = await getTermsOfService(showSnackbar); // Fetch only content
                setTermsContent(content); // Set terms directly
            } catch (error) {
                showSnackbar('Failed to load Terms of Service.', 'error');
            } finally {
                setLoading(false);
            }
        };
        fetchTerms();
    }, []);

    if (loading) return <CircularProgress />;

    return (
        <Box sx={{ padding: '20px' }}>
            <Typography variant="h4" gutterBottom>
                Terms of Service
            </Typography>
            {termsContent ? (
                <div dangerouslySetInnerHTML={{ __html: termsContent }} />
            ) : (
                <Typography color="error">Failed to load Terms of Service.</Typography>
            )}
        </Box>
    );
}
